.reactPiGantt,
.piGantt {
  width: 100%;
  height: 100%;
}

.reactPiGantt {
  overflow: hidden;
  border: 1px solid #f6f6f6;
  border-bottom: none;
  // border-radius: 15px 15px 0 0;
}

.piGantt {
}

.piGanttOverlayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.quickAddWrap {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  pointer-events: auto;
  .hoverBorder {
    width: 100%;
    height: 1px;
    background-color: transparent;
  }
  &:hover .hoverBorder {
    background-color: #316ef5;
  }
}
.btnWrap {
  position: absolute;
  top: -8px;
  left: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #f6f6f6;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  zoom: 1;
  i {
    color: #6b7a96;
    font-size: 10px;
  }
  &:hover {
    background-color: #316ef5;
    border-color: #316ef5;
    zoom: 1.2;
    i {
      color: #fff;
    }
  }
}

.statusMenuWrap {
  position: absolute;
  pointer-events: none;
  :global {
    .status-btn {
      color: transparent;
      background-color: transparent;
      i {
        color: transparent !important;
      }
    }
  }
}

.dark {
  &.reactPiGantt {
    border-color: #ffffff0d;
  }
  .btnWrap {
    color: #d6e0ff99;
    background-color: rgba(36, 45, 63, 0.8);
    border-color: rgba(36, 45, 63, 0.8);
    &:hover {
      background-color: rgba(36, 45, 63, 0.8);
      i {
        color: rgba(49, 110, 245, 1);
      }
    }
  }
  .quickAddWrap {
    &:hover .hoverBorder {
      background-color: #fff;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;