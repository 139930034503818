.setting {
  :global {
    .ant-form {
      padding: 4px 16px;
      padding-top: 24px;
    }
    .ant-form-item-label {
      color: #242d3f;
      font-weight: bold;
      font-size: 14px;
    }
    .ant-pro-form-group-title {
      margin-block-end: 16px;
    }
    .ant-pro-form-group {
      .ant-form-item-label > label {
        color: #9d9fa5;
        font-size: 14px;
      }
    }

    .ant-form-item-label > label {
      width: 100%;
    }

    .ant-space.ant-space-vertical.ant-space-align-start.ant-pro-form-group-container {
      width: 100%;
      > * {
        width: 100%;
      }
    }
    .ant-row-space-between {
      width: 100%;
    }
  }
}

.wrapper {
  :global {
    .fallback-panel {
      margin-top: 40px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;