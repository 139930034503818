.card {
  position: relative;
  width: 175px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  cursor: pointer;

  .radio {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-right: 16px;
    color: #5c6c8f;
    font-size: 18px;
    background-color: #d6e2fd;
    border-radius: 36px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;