.repeat-form {
  color: #242e41;
  .ant-radio-wrapper {
    margin-bottom: 8px;
  }
  .err-tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 404px;
    color: #b7bac0;
    &.has-status {
      height: 336px;
    }
  }
  .status-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    color: #767c88;
    font-size: 12px;
    opacity: 0.3;
    &.enabled {
      opacity: 1;
      .status-info-item {
        .iconparticipants_add_n {
          &:hover {
            color: #316ef5;
          }
        }
        &-owner {
          &.adding {
            .ant-avatar-circle {
              border: 1px solid #316ef5;
            }
          }
          &:hover {
            .ant-avatar-circle {
              border: 1px solid #316ef5;
            }
          }
        }
        &-participant {
          &:hover {
            .ant-avatar-circle {
              //border: 1px solid #316ef5;
              box-shadow: 0 0 0 1px #316ef5;
            }
          }
        }
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 44px;
      cursor: pointer;
      .ant-avatar {
        background: #fff;
        box-shadow: 2px 0 2px 1px rgba(0, 0, 0, 0.05);
        .ant-avatar-string {
          color: #767c88;
        }
      }
      .ant-avatar-group .ant-avatar:not(:first-child) {
        margin-left: -8px;
      }
      .icon-err {
        color: #f0665e;
      }
      &-btn {
        background: none;
        i {
          font-size: 18px;
        }
      }
      .iconparticipants_add_n {
        margin-top: 2px;
        font-size: 24px;
      }
      &.update {
        cursor: default;
      }
      &.avatar {
        flex-direction: row;
      }
      &-owner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //align-items: center;
        margin-right: 24px;
        &.only-owner {
          margin-right: 0;
        }
      }
      &-participant {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-avatar {
          display: flex;
          align-items: center;
          .node-avatar:not(:first-child) {
            margin-left: -8px;
            border-radius: 50%;
          }
          .iconparticipants_add_n {
            margin-left: -8px;
          }
        }
      }

      &-top {
        &.description {
          color: #b6b8b9;
          line-height: 12px;
        }
      }
    }
  }
  .form-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 345px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 32px;
    &.header {
      padding-bottom: 17px;
      border-bottom: 1px solid #ebedf0;
      .repeat-template {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98px;
        height: 24px;
        margin-left: 121px;
        padding: 0 8px;
        color: #242d3f;
        font-size: 12px;
        background: #f8f9fb;
        border-radius: 8px;
        cursor: pointer;
        i {
          font-size: 12px;
        }
        .icontopic_type {
          margin-right: 4px;
        }
        .iconattribute_fold {
          margin-left: 4px;
          color: #b6b8b9;
        }
        &.disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
    }
    .effective-date {
      //margin-left: 82px;
      width: 262px;
    }
    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 262px;
      height: 32px;
      padding: 0 12px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      .range {
        display: flex;
        align-items: center;
        cursor: pointer;
        &-start {
          width: 40px;
        }
        .anticon {
          margin: 0 20px;
        }
      }
      i {
        color: #bfbfbf;
      }
      &.disabled {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        cursor: not-allowed;
        .range {
          cursor: not-allowed;
        }
      }
    }
    &-label {
      margin-right: 12px;
      color: #242e41;
    }
    .ant-picker,
    .ant-select {
      width: 262px;
    }
    .ant-input-number {
      width: 88px;
      margin-right: 8px;
    }
    .err-item {
      border-color: #f0665e;
      .ant-select-selector {
        border-color: #f0665e;
      }
    }
    .week-select {
      width: 44px;
      height: 32px;
      color: #242e41;
      line-height: 32px;
      text-align: center;
      background: #fff;
      border: 1px solid #ddd;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #316ef5;
        border: 1px solid #316ef5;
      }
    }
    .month-select {
      width: 24px;
      height: 24px;
      margin: 4px 11px;
      color: #242e41;
      line-height: 24px;
      text-align: center;
      border-radius: 2px;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #316ef5;
      }
      // &.weekend {
      //   margin-right: 0;
      // }
    }
    .year-select {
      width: 40px;
      margin: 4px 22px;
      color: #242e41;
      line-height: 24px;
      text-align: center;
      border-radius: 2px;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #316ef5;
      }
      // &.monthEnd {
      //   margin-right: 0;
      // }
    }
    .week-select:first-child {
      border-radius: 4px 0 0 4px;
    }
    .week-select:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
  .form-item:last-child {
    margin-bottom: 0;
  }
  .form-btn {
    display: flex;
    flex-direction: row-reverse;
    button {
      margin-left: 24px;
    }
  }
}
.repeat-range-pop {
  padding: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: 6px;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.1);
  }
  .ant-popover-inner-content {
    padding: 6px 0;
    .date {
      &-title {
        padding: 0 0 6px 12px;
        border-bottom: 1px solid #f0f0f0;
      }
      &-main {
        display: flex;
        height: 192px;
        &-item {
          width: 56px;
          &.day {
            width: 80px;
          }
          .number-item {
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            &.active {
              color: #666;
              font-weight: 600;
              background: #f5f5f5;
            }
            &.disabled {
              color: #00000040;
              //background: #f5f5f5;
              cursor: not-allowed;
            }
          }
        }
      }
      &-bottom {
        padding: 6px 0 0 12px;
        color: #316ef5;
        border-top: 1px solid #f0f0f0;
        cursor: pointer;
      }
    }
  }
}

.repeat-task-popover {
  padding: 0;
  .ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.1);
  }
  .ant-popover-inner-content {
    padding: 24px 24px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;