.gridStackContainer {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-x: hidden;

  .sectionGridStackContainer {
    height: 100%;
  }

  .tabGridStack {
    height: 100%;
    overflow: hidden;
    border: 2px dashed transparent;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(77, 99, 146, 0.1);
    cursor: pointer;
    &:hover {
      border-color: #6b7a96;
    }

    .tabSectionToolsContainer {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 3px;
    }

    :global {
      .ant-tabs {
        height: 100%;
      }
      .ant-tabs-nav {
        height: 40px;
        margin: 0 !important;
      }
      .ant-tabs-tab {
        background-color: rgba(0, 0, 0, 0);
        border: none !important;
        border-radius: 6px 6px 0 0 !important;
      }

      .ant-tabs-content {
        height: 100%;
      }
      .ant-tabs-tabpane {
        height: 100%;
        background-color: var(--bg-color);
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        background-color: var(--bg-color) !important;
      }
    }
  }
  .activeTabSection {
    border-color: #6b7a96 !important;
    border-style: solid !important;
  }
  .subGridStack {
    height: 100%;
    overflow: auto;
    border: 2px dashed transparent;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(77, 99, 146, 0.1);
    cursor: pointer;

    &:hover {
      border-color: #6b7a96;
    }

    :global {
      .grid-stack {
        height: 100% !important;
        min-height: 0 !important;
        overflow-x: hidden;
      }
    }
  }

  .activeGeneralSection {
    border-color: #6b7a96;
    border-style: solid !important;

    .generalSectionToolsContainer {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 3px;
    }
  }

  .activeDynamicHeightSection {
    border-color: #6b7a96;
    border-style: solid !important;

    .dynamicHeightSectionToolsContainer {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 3px;
    }
  }
}

.tip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #bfc6d2;
  font-size: 14px;
}

.del,
.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 4px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(77, 99, 146, 0.5);
  cursor: pointer;
}

.activeComp {
  border-color: #316ef5 !important;
  border-style: solid !important;
}

.compContainer {
  height: 100%;
}
.comp {
  height: 100%;
  border: 2px dashed transparent;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    border-color: #316ef5;
  }

  .compToolsContainer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 3px;
  }
}

.compContent {
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
}

.title {
  padding: 16px;
  padding-bottom: 0;
  div {
    height: 24px;
    padding-left: 8px;
    color: #242d3f;
    font-weight: 700;
    line-height: 24px;
    // background-color: #3662ec;
  }
}

.notClone {
  i,
  span {
    color: rgba(107, 122, 150, 0.3) !important;
  }
  cursor: not-allowed;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;