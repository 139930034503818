.content {
  display: inline-block;
  width: 180px;
  height: 36px;
  overflow: hidden;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  cursor: pointer;
}

.picker {
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  :global {
    div[title='rgba(0,0,0,0)'] {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=')
        left center !important;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;