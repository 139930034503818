#NewNodeProp {
  .prop-item-value {
    &-label {
      position: relative;
      display: flex;
      align-items: center;
    }

    &-affix {
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        margin-left: 4px;
        color: #d8d8d8;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #eff1f5;
        }
      }
    }
  }

  .enable-prop {
    &:hover {
      background-color: #eff4ff;
    }

    user-select: none;
  }
}

.enum-prop-select {
  width: fit-content !important;
  max-width: 600px;
}

.attachment-cell {
  display: flex;
  user-select: none;

  &.upload {
    cursor: pointer;
  }

  .attachment-cell-handle {
    height: 100%;

    .attachment-cell-handle-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border: 1px dashed #c9d0d9;
      cursor: pointer;
    }
  }

  .attachment-cell-files {
    display: flex;
    flex: 1;

    .attachment-item {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-left: 2px;
      border: 1px solid #c9d0d9;
      cursor: pointer;
    }
  }
}

.attachment-spin-wrapper {
  width: 100%;
}

.attachment-spin .ant-spin-text {
  top: 0 !important;
}

.AttachmentItem-popover-overlay {
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-inner {
    background-color: transparent;
  }
}

.AttachmentItem-popover {
  position: relative;
  width: 120px;
  height: 170px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;

  .AttachmentItem-popover-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
  }

  .AttachmentItem-popover-handle {
    position: absolute;
    top: 88px;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 32px;
    padding: 0 10px;
    background-color: rgba(36, 45, 63, 0.6);

    .AttachmentItem-popover-handle-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 100%;
      margin-left: 10px;
      cursor: pointer;

      &.preview-button {
        position: absolute;
        left: 10px;
        margin: 0;
      }
    }
  }

  .AttachmentItem-popover-info {
    display: -webkit-box;
    height: 50px;
    padding: 8px;
    overflow: hidden;
    color: #242d3f;
    font-size: 12px;
    white-space: normal;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.groupNameDefault {
  position: relative;
  margin: 6px 0 6px 0;
  padding-left: 8px;
  color: #767c88;
  font-weight: bold;

  ::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3px;
    height: 10px;
    background-color: #316ef5;
    transform: translateY(-50%);
    content: '';
  }
}

.allow-to-open-templates {
  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.graphicCodePopover {
  .ant-popover-inner-content {
    width: 180px;
    padding: 0;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;