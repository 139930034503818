.wrapper {
  box-shadow:
    -4px 0px 8px 0px #ebeef7,
    -1px 0px 0px 0px #ebedf0;

  :global {
    .ant-form-item + .ant-form-item:not(:has(.ant-form-item-label)) {
      margin-top: -12px;
    }

    .ant-form-item-label {
      font-weight: 700;
    }
  }
}

.title {
  box-shadow: 0px 1px 0px 0px #ebeef7;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;