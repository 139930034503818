.search {
  flex-shrink: 0;
  width: 160px;
  height: 32px;
  color: #c9ccce;
  background-color: #f9fafc !important;
  border-radius: 8px !important;
  i {
    color: #b6b8b9;
  }
}

.darkSearch {
  :global {
    .ant-input-prefix {
      color: rgba(214, 224, 255, 0.6);
    }
    .ant-input {
      color: white;
    }
  }
  background-color: rgba(214, 224, 255, 0.05) !important;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;