.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 56px;
}

.dashboardList {
  flex: 1;
  height: 0;
}

.addPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 308px;
  height: 160px;
  color: #316ef5;
  font-size: 14px;
  background-color: #f8f9fb;
  border: 1px dashed #ebedf0;
  border-radius: 8px;
  cursor: pointer;
}
.gridLayoutItemWrapper {
  width: 100%;
  height: 100%;

  /**
   * NOTE: fix LINKPI-40529
   * https://github.com/react-grid-layout/react-grid-layout/issues/858
  */
  &:hover {
    z-index: 3; // 2 太保守，10 太激进，3 稳重且先锋
  }
}

.cardWrapper {
  width: 100%;
  height: 100%;
  :global {
    .ant-spin-container {
      width: 100%;
      height: 100%;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // background-color: #fff;
  background-color: transparent;
  border: 1px solid #ebe9e9;
  border-radius: 8px; //transparent;//#15161A;
  &.deleteCard {
    background-color: #fafafb;
  }

  &:hover {
    .cardHeaderTools {
      display: flex;
    }
  }
}
.cardHeader {
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  padding: 0 12px 0 4px;

  :global {
    .draggableHandle {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      line-height: 20px;
      text-align: center;
      cursor: move;
      i {
        color: #97a1b5;
        font-size: 20px;
      }
    }
  }
}
.cardHeaderName {
  flex: 1;
  color: #242d3f;
  font-size: 12px;
  .cardHeaderNameLine {
    color: #316ef5;
    text-decoration: underline;
  }
}
.cardHeaderTools {
  display: flex;
  align-items: center;
}
.cardHeaderToolsItem {
  width: 32px;
  height: 32px;
  margin-left: 5px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;
  :global {
    .ant-btn,
    .ant-btn:hover,
    .ant-btn:focus {
      border-radius: 8px;
    }
  }
}

.cardHeaderDarkToolsItem {
  width: 32px;
  height: 32px;
  margin-left: 5px;
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;
  :global {
    .ant-btn,
    .ant-btn:hover,
    .ant-btn:focus {
      background-color: rgba(248, 249, 251, 0.05);
      border-radius: 8px;
    }
  }
}
.cardContentWrap {
  flex: 1;
  height: 0;
}
.cardContent {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .deleteCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .deleteCardContentIcon {
      width: 75px;
      height: 75px;
      margin-bottom: 5px;
      background-color: #e5e7eb;
      border-radius: 75px;
    }
    .deleteCardContentText {
      padding: 0 15px;
      color: #767c88;
      font-size: 14px;
    }
    .deleteCardContentBtn {
      display: inline;
      color: #316ef5;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

:global {
  .react-grid-item.react-grid-placeholder {
    background-color: #f4f7fd;
    opacity: 1;
  }
  .react-grid-item > .react-resizable-handle {
    z-index: 2;
    overflow: hidden;
    background-image: none;
    &::after {
      right: 8px;
      bottom: 8px;
      width: 20px;
      height: 20px;
      border-right: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-radius: 8px;
    }
  }
  .react-grid-item:hover {
    .react-resizable-handle::after {
      border-color: #316ef5;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;