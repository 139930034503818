#QuoteSelector {
  .quote-option {
    &-header {
      display: flex;
      align-items: center;
      height: 40px;
      margin: 8px 0;
      padding: 0 6px;
      color: #767c88;
      background: #f9fafc;
      border-radius: 8px;

      &-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
      }
    }

    &-body {
      height: 524px;
      //overflow-y: auto;
      color: #242e41;
      & + .ps__rail-x {
        display: none;
      }

      &-column {
        display: flex;
        //height: 80px;
        margin-bottom: 4px;
        padding: 12px 6px 0 6px;
        color: #242e41;
        background: #f9fafc;
        border: 1px solid #f9fafc;
        border-radius: 8px;
        cursor: pointer;

        &:hover,
        &.active {
          background: #f0f6ff;
          border-color: #f0f6ff;
        }

        &.active {
          border-color: #316ef5;
        }

        &-item {
          &-label {
            display: flex;
            margin-left: 12px;
            font-weight: 600;
            line-height: 14px;

            &-aux {
              color: rgb(185, 187, 188);
            }
          }

          &-main {
            display: flex;
            align-items: center;
            height: 52px;

            &-cell {
              padding: 0 12px;

              //background: #f9fafc;
              i {
                margin-right: 4px;
                font-size: 18px;
              }

              &.status {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }

    .new-option {
      margin-top: 22px;
      color: #b6b8b9;
      font-size: 14px;
    }
  }
}

.quote-node-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 12px;
  color: #316ef5;
  font-size: 14px;
  background: #f0f6ff;
  border-radius: 8px;
  cursor: pointer;

  .iconButton_add {
    font-size: 14px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;