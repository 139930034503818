#Board {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto hidden;
  .board-default-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    .add-btn {
      width: 121px;
      margin-top: 40px;
      border-radius: 8px;
    }
  }
  .board-list {
    display: flex;
    flex-direction: column;
    width: 242px;
    height: 100%;
    margin-right: 16px;
    padding-bottom: 8px;

    .board-list-head {
      display: flex;
      align-items: center;
      height: 40px;
      margin: 0 0 8px 0;
      padding-left: 32px;
      color: #242d3f;
      font-size: 16px;
      line-height: 40px;
      background-color: #d4effd;
      border-radius: 6px;
      .group-title {
        max-width: 160px;
        font-weight: 500;
      }
      .group-count {
        margin-left: 10px;
        font-weight: 400;
      }
    }

    .board-list-body {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-shrink: 0;
      width: 242px;
      height: 0;
      border-radius: 6px;
      .board-list-mask {
        position: absolute;
        z-index: 10000;
        display: flex;
        justify-content: center;
        width: 242px;
        height: 100%;
        background-color: #fff;
        border: 1px solid #316ef5;
        border-radius: 6px;
        opacity: 0.9;
        &-tip {
          margin-top: 22px;
          color: #fda84a;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .board-add-card {
        width: 100%;
        padding: 16px 0;
        background: #f9fafc;
        border-radius: 6px 6px 0 0;
        &.noadd {
          padding: 8px 0;
        }
        .board-add-btn {
          width: 210px;
          height: 40px;
          margin: 0 16px;
          color: #b7bac0;
          border: 1px solid #eef1f5;
          border-radius: 6px;
          i {
            margin-right: 4px;
            font-size: 16px;
          }
          &:hover {
            color: #316ef5;
          }
        }
      }
      .board-card-list {
        flex: 1;
        flex-shrink: 0;
        //padding-top: 8px;
        //padding: 16px 0;
        width: 100%;
        height: 0;
        // overflow-y: auto;
        // background: #f9fafc;
        border-radius: 6px;
        &.noadd {
          padding-top: 16px;
          background: #f9fafc;
        }
      }
      .has-add-btn {
        .board-card {
          margin-top: 0;
          //padding: 0 0 16px 0;
          //border-radius: 0 0 6px 6px;
        }
      }

      .cant {
        padding-bottom: 12px;
        & > div {
          position: relative;
        }
      }
    }
  }
}

.board-card-wrapper {
  width: 100%;
  height: 100%;
  background-color: #f9fafc;
}
.board-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 210px !important;
  height: 150px;
  margin: 0 auto 12px;
  padding: 12px 0 0 16px;
  background: #fff;
  border: 1px solid #eef1f5;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 #eef1f5;
  //margin-top: 12px;
  cursor: pointer;

  .card-title {
    display: flex;
    margin: 5px 0;
    .title {
      width: 156px;
      margin: 0;
      color: #242e41;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      word-break: break-all;
    }
    .status-btn {
      top: -2px;
      // width: 18px;
      // height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      i {
        font-size: 18px;
      }
    }
  }
  .title-only {
    margin-bottom: 15px;
  }
  .endDate {
    display: flex;
    align-items: center;
    height: 32px;
    color: #242d3f;
    font-size: 12px;
    //margin-bottom: 8px;
    //width: 146px;
    border-radius: 6px;
    i {
      margin-right: 4px;
      color: rgba(55, 69, 98, 1);
      font-size: 12px;
    }
  }
  .show-prop {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 6px 0;
    &-item {
      display: flex;
      align-items: center;
      min-width: 32px;
      height: 20px;
      margin-right: 6px;
      padding: 0 8px;
      color: #316ef5;
      font-size: 12px;
      line-height: 1;
      background-color: #eff4ff;
      border-radius: 10px;
    }
  }
  .overdue {
    //background-color: #fef2f0;
    color: #eb5e3a;
    i {
      color: #eb5e3a;
    }
  }
  .foot {
    display: flex;
    align-items: center;
    height: 32px;
    i {
      margin: 4px 6px 0 0;
      color: #b7bac0;
      font-size: 12px;
    }
    .send {
      margin-right: 6px;
    }
  }

  .own {
    //img,
    .avatar-white {
      width: 20px;
      height: 20px;
      background-color: #ccc;
      border-radius: 20px;
    }
    .ant-avatar {
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
    }
  }
}
.board-card-dragging {
  opacity: 0.4;
}
.board-card-top {
  margin-top: 12px;
}

#Board.darkBoard {
  .board-list {
    &:nth-child(10n-9) {
      .board-list-head {
        color: #6890eb;
        background-color: rgba(104, 144, 235, 0.1);
      }
    }

    &:nth-child(10n-8) {
      .board-list-head {
        color: rgba(47, 192, 255, 1);
        background-color: rgba(47, 192, 255, 0.1);
      }
    }
    &:nth-child(10n-7) {
      .board-list-head {
        color: rgba(17, 197, 167, 1);
        background-color: rgba(17, 197, 167, 0.1);
      }
    }
    &:nth-child(10n-6) {
      .board-list-head {
        color: rgba(151, 255, 47, 1);
        background-color: rgba(151, 255, 47, 0.1);
      }
    }
    &:nth-child(10n-5) {
      .board-list-head {
        color: rgba(249, 208, 83, 1);
        background-color: rgba(249, 208, 83, 0.1);
      }
    }
    &:nth-child(10n-4) {
      .board-list-head {
        color: rgba(255, 160, 47, 1);
        background-color: rgba(255, 160, 47, 0.1);
      }
    }
    &:nth-child(10n-3) {
      .board-list-head {
        color: rgba(249, 208, 83, 1);
        background-color: rgba(249, 208, 83, 0.1);
      }
    }
    &:nth-child(10n-2) {
      .board-list-head {
        color: rgba(151, 255, 47, 1);
        background-color: rgba(151, 255, 47, 0.1);
      }
    }
    &:nth-child(10n-1) {
      .board-list-head {
        color: rgba(17, 197, 167, 1);
        background-color: rgba(17, 197, 167, 0.1);
      }
    }
    &:nth-child(10n) {
      .board-list-head {
        color: rgba(47, 192, 255, 1);
        background-color: rgba(47, 192, 255, 0.1);
      }
    }
  }

  .board-list-body-darkbg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
  }

  .board-add-card {
    background: transparent !important;
    & > button {
      background-color: rgba(214, 224, 255, 0.05);
      border: none !important;
    }
  }

  .board-card-wrapper {
    background-color: transparent;
  }
  .board-card {
    background-color: rgba(214, 224, 255, 0.03);
    border: none;
    box-shadow: none;
    .card-title .title {
      color: rgba(255, 255, 255, 1);
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;