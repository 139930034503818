.rule-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  padding-top: 24px;
  overflow-y: auto;
}
.rule-panel-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  min-height: 72px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(107, 122, 150, 0.2);
  &.left::before {
    position: absolute;
    top: 50%;
    left: -50%;
    width: 50%;
    height: 100%;
    border-top: 2px solid #ebeef7;
    border-left: 2px solid #ebeef7;
    border-top-left-radius: 44px;
    content: ' ';
  }
  &.right::before {
    position: absolute;
    top: 50%;
    right: -50%;
    width: 50%;
    height: 100%;
    border-top: 2px solid #ebeef7;
    border-right: 2px solid #ebeef7;
    border-top-right-radius: 44px;
    content: ' ';
  }
}
.rule-panel-des-title {
  font-size: 16px;
}
.rule-panel-des-sub {
  font-size: 12px;
}

.rule-card-wrapper {
  position: relative;
}
.rule-card {
  position: relative;
  width: 520px;
  min-height: 168px;
  margin-top: 16px;
  padding: 24px 16px;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(107, 122, 150, 0.2);
  .rule-card-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1400px;
    border-bottom-right-radius: 260px;
    border-bottom-left-radius: 260px;
    transform: scaleY(0.08);
    transform-origin: top;
  }
}
.delete {
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transform: translate(calc(100% + 20px), -50%);
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    color: @primary-color;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;