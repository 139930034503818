.ruleTable {
  .ruleTitle {
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .actions {
    line-height: 1;
  }

  :global {
    .ant-table table {
      border-collapse: separate;
      border-spacing: 0 8px;
    }

    // 默认 display: table, 会被 border-space 影响
    .ant-checkbox-inner::after {
      display: inline-flex !important;
    }
    .ant-table {
      background-color: white;
    }
    tr > th {
      color: #b7bac0;
      border: 0;
    }
    tr {
      & > th,
      & > td {
        &:first-of-type {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-of-type {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
    .ant-table-row,
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      background-color: #f7f8fa;
      border: 0;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-top: 20px;
  line-height: 1;
  .title {
    font-size: 16px;
  }
  .enableNum {
    color: @primary-color;
    font-weight: bold;
  }

  :global {
    .ant-btn {
      border-radius: 8px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;