.node-import-modal {
  .ant-modal-title {
    color: #1e1f21;
    font-weight: 500;
    font-size: 18px;
  }
  .ant-modal-body {
    padding: 15px 40px 24px 24px;
  }
  .import-hidden {
    display: none;
  }
  .import-description {
    color: #1e1f21;
    font-size: 14px;
    line-height: 20px;
  }
  .export-tips {
    margin: 8px 0 12px 0;
    color: #b6b8b9;
    font-size: 14px;
    line-height: 20px;
  }
  .qr-export {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    //justify-content: space-between;
    .qr-template {
      width: 220px;
      margin-right: 46px;
    }
  }
  .qr-form-label {
    margin: 0 0 12px 0;
    color: #767c88;
  }
  .qr-prop-map {
    display: flex;
    flex-direction: column;
    margin: 12px 0 0 0;

    .form-list-item {
      //display: flex;
      //align-items: center;
      width: 100%;
      margin-bottom: 16px;
      .item-split {
        display: inline-block;
        margin: 0 16px 0 8px;
        color: #316ef5;
        font-size: 16px;
        line-height: 32px;
        //margin-bottom: 24px;
      }
      .list-item {
        display: inline-block;
        width: 220px;
        margin: 0 8px 0 0;
      }
      .list-remove-btn {
        display: inline-block;
        margin-left: 8px;
        padding: 6px 9px;
        color: #6b7a96;
        font-size: 6px;
        background: #f7f9fb;
        border: none;
        border-radius: 50%;
        &:hover {
          background: #f4f7fd;
        }
      }
    }
    .form-list-add {
      width: 136px;
      margin-bottom: 20px;
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #316ef5;
        background: #f7f9fb;
        border: none;
        &:hover {
          background-color: #f4f7fd;
        }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    button {
      margin: 0 8px 0 16px;
    }
  }
  .export-footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    .footer-download {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      .history-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 94px;
        margin-right: 20px;
        padding: 5px 8px;
        color: #316ef5;
        border-radius: 4px;
        cursor: pointer;
        i {
          color: #316ef5;
          font-size: 12px;
        }
        &:hover {
          background: #f4f7fd;
        }
      }
    }
    .footer-buttons {
      display: flex;
      align-items: center;
      .footer-btn {
        min-width: 62px;
        height: 32px;
        margin-left: 24px;
        border-radius: 4px;
      }
      .footer-ok {
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #316ef5;
        border-color: #316ef5;
        &:hover {
          background-color: #40a9ff;
          border-color: #40a9ff;
        }
      }
    }
  }
  .qr-history-drawer {
    position: absolute;
    .ant-drawer-content {
      border-radius: 6px;
    }
    .ant-drawer-mask {
      border-radius: 5px;
    }
    .history-title {
      color: #242d3f;
      font-weight: 500;
      font-size: 18px;
    }
    .ant-drawer-body {
      padding: 8px 0;
      .history-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 66px;
        padding: 17px 32px 17px 24px;
        .history-item-main {
          display: flex;
          align-items: center;
          width: 400px;
          .history-name {
            width: 300px;
            margin-left: 12px;
            .zip-name {
              color: #242e41;
            }
            .history-time {
              color: #b6b8b9;
              font-size: 12px;
            }
          }
        }
        .history-item-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 104px;
          height: 32px;
          padding: 6px 16px 6px 12px;
          color: #316ef5;
          background-color: #f7f9fb;
          border-radius: 4px;
          cursor: pointer;
          i {
            font-size: 12px;
          }
          &:hover {
            background-color: #f4f7fd;
          }
        }
      }
    }
  }
  .ant-progress-status-success .ant-progress-bg {
    background-color: #5abcb0;
  }
  .ant-progress-text {
    display: none;
  }
}
.template-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
  span {
    width: 100px;
  }
  i {
    color: #316ef5;
    font-weight: 400;
    font-size: 12px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;