@footer-height: 32px;
@padding-bottom: 20px;
@modal-padding: 24px + 32px;

.picker {
  position: relative;
  display: flex;
  height: 360px + 32px;
  padding-top: 32px;
  &.full {
    position: absolute;
    width: calc(100% - 48px);
    height: calc(100% - @modal-padding - @footer-height - @padding-bottom);
  }
}

.tab {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 32px;
  .tabItem {
    padding: 0 15px;
    color: #242e41;
    font-size: 14px;
    line-height: 32px;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    &:hover,
    &.tabActive {
      background-color: #f8f9fb;
    }
  }
}

.list {
  width: 34%;
  margin-right: 24px;
  padding: 8px;
  font-size: 14px;
  background-color: #f8f9fb;
  border-radius: 8px;
  &.listFixRadius {
    border-top-left-radius: 0;
  }
  .listPlaceholder {
    color: #b7bac0;
    line-height: 40px;
    text-indent: 12px;
  }
  .groupName {
    color: #b7bac0;
    line-height: 40px;
    text-indent: 12px;
  }
  .listItem {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;
    color: #242e41;
    line-height: 40px;
    border-radius: 8px;
    cursor: pointer;

    &.listItemActive {
      color: #316ef5;
      font-weight: 400;
      background-color: #fff;
    }

    .icon {
      width: 16px;
      margin-right: 10px;
      .iconfont {
        color: #6b7a96;
        font-size: 16px;
      }
    }

    .pickItemName {
      flex: 1;
    }

    // 下拉三角形
    .triangle {
      width: 0;
      height: 0;
      margin: 0 14px 0 10px;
      border: 4px solid transparent;
      border-top: 4px solid #6b7a96;
    }
  }
}
.preview {
  flex: 1;
  max-height: 392px;
  padding: 20px;
  background-color: #f8f9fb;
  border-radius: 8px;
  .name {
    color: #242e41;
    font-weight: 600;
    font-size: 16px;
  }
  .subTitle {
    margin-top: 24px;
    color: #767c88;
    font-size: 14px;
  }
  .code {
    margin-top: 4px;
    padding: 12px;
    font-size: 14px;
    white-space: pre-wrap;
    background-color: #fff;
    border-radius: 8px;
  }
  .codeRes {
    color: #e48c26;
  }
  :global {
    .CodeMirror-line {
      margin-bottom: 0;
      white-space: pre-wrap;
    }
  }
}

.previewPlaceholder {
  color: #b7bac0;
  text-align: center;
}

:global {
  /* 属性变量 */
  .cm-variable {
    color: #6caccf !important;
  }

  /* 字符 */
  .cm-string,
  .cm-number {
    color: #746aca !important;
  }

  /* 函数 */
  .cm-property {
    color: #65b480 !important;
  }

  /* 符号 */
  .cm-operator {
    color: #e48c27 !important;
  }

  /* 数字 */
}

.randomNumber {
  padding: 10px 16px;
  .randomNumberBtns {
    padding: 16px 0 8px;
    text-align: right;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;