.container {
  .item {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .label {
      width: 22%;
      text-align: right;
      padding-right: 6px;
    }
    .form {
      width: 78%;
    }

    :global {
      .ant-picker-calendar {
        width: 300px;
        border: 1px solid #ebedf0;
        border-radius: 8px;
        margin-top: 16px;
        .ant-radio-group {
          display: none;
        }
      }
    }
  }
}

.range {
  height: 32px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid rgb(217, 217, 217);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 262px;
}

.pop {
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .title {
    padding: 6px 12px;
    border-bottom: 1px solid #f0f0f0;
  }
  .bottom {
    padding: 6px 12px;
    color: #316ef5;
    border-top: 1px solid #f0f0f0;
    cursor: pointer;
  }
  .main {
    display: flex;
    height: 192px;
    .item {
      height: 32px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .active {
      color: #666;
      font-weight: 600;
      background: #f5f5f5;
    }
  }
}

.weekDay {
  width: 44px;
  height: 32px;
  color: #242e41;
  background: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.dayActive {
  color: #fff;
  background: #316ef5;
  border: 1px solid #316ef5;
}

.date {
  width: 24px;
  height: 24px;
  margin: 4px 11px;
  color: #242e41;
  border-radius: 2px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.dateActive {
  color: #fff;
  background: #316ef5;
}

.month {
  width: 40px;
  margin: 4px 22px;
  color: #242e41;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  user-select: none;
}

.monthActive {
  color: #fff;
  background: #316ef5;
}

.divider {
  background-color: #ebedf0;
  height: 1px;
  margin: 24px 15px 0 15px;
}

.disabledWeekDay {
  .weekDay {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;