.pi-gantt-wrapper,
.pi-gantt-canvas-wrapper,
.pi-gantt-overlayer {
  position: relative;
  width: 100%;
  height: 100%;
}

.pi-gantt-overlayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.pi-gantt-canvas {
  user-select: none;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;