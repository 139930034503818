.setting {
  width: 320px;
  border-left: none;
  box-shadow:
    -4px 0px 8px 0px #ebeef7,
    -1px 0px 0px 0px #ebedf0;
  display: flex;
  flex-direction: column;
  :global {
    .anticon.anticon-down {
      font-size: 12px !important;
    }
  }
}
.head {
  height: 48px;
  background-color: #f7f7f7;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #242d3f;
  font-weight: 700;
  box-shadow: 0px 1px 0px 0px #ebeef7;
  justify-content: space-between;
}

.content {
  padding: 12px;
  flex: 1;
  overflow-y: auto;

  .tab {
    margin-bottom: 24px;
  }
  :global {
    .ant-form-item-label {
      font-size: 14px;
      color: #242d3f;
      font-weight: 700;
      label {
        width: 100%;
      }
    }
  }
  .switchIndexIcon {
    position: absolute;
    right: 0;
    top: -30px;
  }
  .indexIconForm {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .subLabel {
    color: #242d3f;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;