.permission-modal {
  .ant-modal-body {
    height: 500px;
    padding: 12px;
    overflow-y: auto;
  }
  .ant-modal-header {
    padding: 12px 16px;
  }
  .ant-modal-close-x {
    line-height: 49px;
  }
  .modal-title {
    position: relative;
    display: flex;
    align-items: center;
    p:nth-child(1) {
      min-width: 74px;
      margin-right: 17px;
      color: #232e41;
      font-size: 18px;
    }
    p:nth-child(2) {
      position: relative;
      padding-right: 20px;
      color: #858fa5;
      font-size: 16px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 82px;
      width: 1px;
      height: 100%;
      background-color: #ebedf0;
      content: '';
    }
  }
  .tabs {
    display: flex;
    margin-bottom: 8px;
    padding: 0 12px;
    & > div {
      position: relative;
      margin-right: 32px;
      padding: 8px 0;
      color: #242e41;
      font-size: 16px;
      line-height: 18px;
      cursor: pointer;
      &.active {
        color: #316ef5;
        font-weight: 600;
        &::after {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #316ef5;
          content: '';
        }
      }
    }
  }
  .node-tab {
    display: flex;
    flex-direction: column;
    &-item {
      display: flex;
      margin: 24px 0 0 12px;
      .ant-checkbox-wrapper {
        color: #767c88;
      }
      &-description {
        margin-right: 16px;
        color: #242e41;
      }
    }
  }
  .member-tab {
    display: flex;
    margin-top: 32px;
    .member-tab-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 125px;
      padding: 0 24px 0 12px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 108px;
        height: 40px;
        margin-bottom: 5px;
        padding: 9px 22px;
        color: #242e41;
        border-radius: 8px;
        &.active {
          color: #316ef5;
          background-color: #f4f7fd;
        }
      }
    }
    .member-tab-right {
      padding-left: 20px;
      border-left: 1px solid #e5e5e5;
    }
    & > div {
      position: relative;
      color: #242e41;
      font-size: 16px;
      line-height: 18px;
      cursor: pointer;
    }
  }
  .editForm {
    .search {
      height: 24px;
      margin-bottom: 12px;
      padding: 0 16px;
      .ant-input,
      .ant-input-affix-wrapper {
        background-color: #f8f9fb;
      }
      i {
        color: #6b7a96;
        font-size: 12px;
        transform: scale(0.9);
      }
    }
    .row {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      color: #242e41;
      font-size: 14px;
      &:hover {
        background-color: #f5f5f5;
      }
      &.header {
        color: #b6b8b9;
        &:hover {
          background-color: initial;
        }
      }
      .role {
        width: 257px;
      }
      .permission {
        width: 128px;
        .ant-select-selector {
          padding: 0 11px 0 0;
        }
      }
      .children {
        flex: 1;
        text-align: center;
      }
    }
  }
  .clearAcl {
    height: 32px;
    margin-right: 250px;
    padding: 0 12px;
    color: #316ef5;
    line-height: 32px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #f4f7fd;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;