.radio-option:has(+ .radio-option-selected) {
  border-right: 0;
}

.radio-option-selected + .radio-option {
  border-left: 0;
}

.radio-option + .radio-option:not(.radio-option-selected) {
  border-left: 0;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;