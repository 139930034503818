.view-content-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-body,
  .ant-modal-content {
    pointer-events: none;
  }
}
.view-content-modal {
  &.full-screen {
    width: 100% !important;
    height: 100% !important;
  }

  top: 0;
  height: 90vh;
  padding-bottom: 0;
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: none;
  }
  .chat-pop {
    position: absolute;
    top: 198px;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    width: 64px;
    height: 40px;
    background-color: #fff;
    border-radius: 100px 0 0 100px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
    //border: 1px solid #F4F4F4;
    cursor: pointer;
    i {
      margin-left: 20px;
      color: #6d7c97;
      font-size: 16px;
    }
    &:hover {
      i {
        color: #316ef5;
      }
    }
  }

  .step-content-btn {
    display: flex;
    align-items: center;
    height: 100%;
    color: #ebedf0;
    pointer-events: auto;

    i {
      padding: 8px 12px;
      font-size: 16px;
      background: rgba(98, 99, 99, 1);
      border-radius: 30px;
      cursor: pointer;
      &:hover {
        background: rgba(98, 99, 99, 0.8);
        //color: #316ef5;
      }
    }
    &.disabled {
      i {
        color: rgba(255, 255, 255, 0.3);
        cursor: not-allowed;
        &:hover {
          background: rgba(98, 99, 99, 1);
        }
      }
    }
    &.left {
      left: -56px;
      padding-right: 12px;
      i {
        transform: rotate(180deg);
      }
    }
    &.right {
      left: 1040px;
      padding-left: 12px;
    }
  }
  .view-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    background-color: white;
    border-radius: 8px;
    &-nav {
      display: flex;
      align-items: center;
      color: #242d3f;
      line-height: 17px;
      .iconfanhui {
        margin-right: 18px;
        font-size: 16px;
        cursor: pointer;
      }
      &-org {
        padding-right: 8px;
        line-height: 15px;
        border-right: 1px solid #242d3f;
      }
      &-title {
        max-width: 140px;
        margin-left: 8px;
        cursor: pointer;
        &.current {
          font-weight: 500;
        }
      }
      .iconroute {
        color: #b6b8b9;
        font-size: 12px;
      }
    }
    .view-content-btn {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      border-bottom: 1px solid #ebedf0;
      .forward-btn {
        display: flex;
        align-items: center;
        color: #767c88;
        cursor: pointer;
        .iconroute {
          margin: 2px 4px 0 0;
          transform: rotate(180deg);
        }
        &:hover {
          color: #316ef5;
        }
      }
      .content-btns {
        display: flex;
        align-items: center;
        .content-btn {
          width: 32px;
          height: 32px;
          margin-left: 8px;
          color: #6b7a96;
          line-height: 32px;
          text-align: center;
          background-color: #f9fafc;
          border-radius: 50%;
          cursor: pointer;

          i {
            font-size: 16px;
          }
          .icongengduo1 {
            display: inline-block;
            transform: rotate(90deg);
          }
          &.collected {
            i {
              color: #ffd031;
            }
          }
          &:hover {
            color: #316ef5;
            background-color: #f4f7fd;
            border: none;
          }
        }
      }
    }
    .view-content-main {
      display: flex;
      flex: 1;
      width: 100%;
      height: 0;
      .chat-page {
        width: 372px;
        border-left: 1px solid #efeff0;
      }
      // 内容页大模型-弹窗中去除不必要的外边距
      .pi-node-page-model {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
  }

  .node-open-in-page {
    width: 118px;
    height: 32px;
    color: #fff;
    font-size: 12px;
    background-color: #626363;
    border: none;
    border-radius: 8px;
    margin-bottom: 8px;
    &:hover {
      border: none;
    }
    &:focus {
    }
    & > span {
      margin-left: 4px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;