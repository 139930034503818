.wrapper {
  display: flex;
  height: 488px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  & > div {
    width: 50%;
  }
}

.rightPanel {
  display: flex;
  flex-direction: column;
  padding: 14px 20px;
  border-left: 1px solid #e5e5e5;
}
.modal {
  :global {
    .ant-modal-footer {
      padding: 20px 24px;
      border: 0;
    }
    .ant-modal-body {
      padding-bottom: 0;
    }
    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 12px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;