.attr {
  padding: 0 16px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :global {
    .ant-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;