@record-bg-color: #e6e3f1;
@dark-bg-color: transparent;

.NodeHistoryStatus {
  padding: 12px 18px;

  .status-record {
    position: relative;

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 22px;
    }

    &-time {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      width: 110px;
      padding-right: 20px;
      color: #b6b8b9;
      font-size: 12px;

      &-main {
        line-height: 18px;
        text-align: right;
      }

      &-dot {
        position: absolute;
        top: 20px;
        left: 104px;
        z-index: 1;
        width: 10px;
        height: 10px;
        background: #fff;
        border: 1px solid #e5e6ea;
        border-radius: 10px;
      }

      &-line {
        position: absolute;
        top: 30px;
        left: 108px;
        z-index: 1;
        width: 1px;
        height: calc(100% - 10px);
        background-color: rgba(214, 224, 255, 0.5);
      }
    }

    &-divider {
      width: 1px;
      height: 100%;
      background: #e5e6ea;
    }

    &-title {
      flex: 1;
      min-width: 400px;

      &-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 25px;
        padding: 14px 12px 16px;
        background-color: @record-bg-color;
        border-radius: 8px 8px 0 0;

        &-name {
          display: flex;
          max-width: 300px;
          color: #242d3f;
          font-weight: bold;
          font-size: 15px;
        }

        &-time {
          color: #767c88;
          font-size: 12px;
        }
      }
    }

    &-item {
      display: flex;
      align-items: start;
      justify-content: space-between;
      line-height: 40px;

      &-content {
        flex: 1;
        min-width: 400px;

        &-main {
          margin-left: 25px;
          padding: 0 12px 8px 12px;
          line-height: 32px;
          background-color: @record-bg-color;

          &-list {
            position: relative;
            padding: 12px 20px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 8px;

            &-item {
              color: #767c88;
              font-size: 12px;
              line-height: 16px;
              background-color: #fff;
              border-radius: 8px;
            }
          }
        }
      }
    }

    &-tail {
      display: flex;
      flex: 1;
      align-items: start;
      justify-content: space-between;
      min-width: 400px;
      line-height: 40px;

      &-content {
        flex: 1;
        min-width: 400px;

        &.hasPadding {
          padding-bottom: 20px;
        }

        &-main {
          margin-left: 25px;
          padding: 0 12px 12px 12px;
          line-height: 32px;
          background-color: @record-bg-color;
          border-radius: 0 0 8px 8px;

          &-list {
            padding: 12px 20px;
            background-color: #fff;
            border-radius: 8px;

            &-item {
              margin-bottom: 12px;
              color: #767c88;
              font-size: 12px;
              line-height: 16px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .head {
    display: flex;
    justify-content: space-between;

    .viewall {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-bottom: 19px;
      padding: 6px;
      font-size: 14px;
      line-height: 1;
      background: #f8f9fb;
      border-radius: 8px 8px 8px 8px;
      cursor: pointer;
      opacity: 1;

      &:hover {
        & i,
        & span {
          color: #316ef5;
        }
      }
    }

    .viewall i {
      color: #242d3f;
      opacity: 1;
    }

    .viewall span {
      margin-left: 3px;
      color: #242d3f;
    }
  }

  .voltName {
    color: #b6b8b9;
    font-size: 12px;
    line-height: 16px;
  }

  .commit {
    margin-top: 12px;
    margin-bottom: 0 !important;
    color: #767c88;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-wrap;
  }

  .icon {
    position: absolute;
    top: -8px;
    right: -8px;
  }

  .history {
    font-size: 12px;
    line-height: 24px;
  }

  .none-status {
    margin: 130px 0 0 216px;
    color: #b6b8b9;
  }
}

.NodeHistoryStatus.dark-mode {
  .status-record {
    &-time {
      color: rgba(214, 224, 255, 0.3);

      &-main {
      }

      &-dot {
        background-color: transparent;
        border: 1px solid rgba(214, 224, 255, 0.05);
      }

      &-line {
        background-color: rgba(214, 224, 255, 0.05);
      }
    }

    &-divider {
      background-color: #e5e6ea;
    }

    &-title {
      &-main {
        background-color: @dark-bg-color;

        &-name {
          color: #ffffff;
        }

        &-time {
          color: rgba(83, 91, 112, 0.5);
        }
      }
    }

    &-item {
      &-content {
        &-main {
          background-color: @dark-bg-color;
          &-list {
            background-color: rgba(104, 144, 235, 0.1);
            &-item {
              color: #cacfde;
              background-color: @dark-bg-color;
            }
          }
        }
      }
    }

    &-tail {
      &-content {
        &.hasPadding {
        }

        &-main {
          background-color: @dark-bg-color;
          &-list {
            background-color: rgba(104, 144, 235, 0.1);
            &-item {
              color: #cacfde;
              background-color: @dark-bg-color;
            }
          }
        }
      }
    }
  }

  .head {
    .viewall {
      background: rgba(214, 224, 255, 0.05);

      &:hover {
        & i,
        & span {
          color: #316ef5;
        }
      }
    }

    .viewall i {
      color: rgba(214, 224, 255, 0.6);
    }

    .viewall span {
      color: rgba(214, 224, 255, 0.6);
    }
  }

  .commit,
  .voltName {
    color: #cacfde;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;