.card {
  &-content {
    .item {
      position: relative;

      &.item-small > *:first-child:not(:last-child) {
        margin-right: 8px;
      }

      & > *:first-child:not(:last-child) {
        margin-right: 24px;
      }

      &:not(:last-child)::after {
        content: ' ';
        display: inline-block;
        width: 2px;
        height: 24px;
        background-color: #fff;
        position: absolute;
        right: 0;
        opacity: 0.2;
      }
    }
  }

  // & + & {
  //   margin-left: 24px;
  // }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;