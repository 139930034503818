#CascadeSetting {
  height: 712px;
  color: #242e41;
  .cascade-type {
    margin-bottom: 20px;
  }
  .cascade-quote {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &-label {
      width: 122px;
      margin-right: 12px;
      color: #242e41;
    }
    .ant-tree-select,
    .ant-select {
      width: 560px;
    }
    &-tips {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 684px;
      height: 164px;
      color: #b7bac0;
      background: #f8f9fb;
      border-radius: 8px;
    }
  }
  .ant-tree {
    .ant-tree-node-content-wrapper:hover {
      background: none;
    }
    .ant-tree-node-selected {
      background: none;
    }
  }
  .title-setting {
    display: flex;
    margin-bottom: 12px;
    &-item {
      display: flex;
      justify-content: space-between;
      width: 140px;
      height: 40px;
      margin-right: 12px;
      padding: 10px 12px;
      background: #f8f9fb;
      border-radius: 8px;
      i {
        color: #6b7a96;
        font-size: 12px;
        visibility: hidden;
        cursor: pointer;
      }
      &:hover {
        i {
          visibility: visible;
        }
      }
    }
  }
  .tree-setting {
    &-node {
      display: flex;
      align-items: center;
      .ant-input {
        margin-right: 12px;
        border-radius: 8px;
      }
      .iconButton_cancel {
        margin: 2px 4px 2px 4px;
        padding: 2px 8px;
        color: #6b7a96;
        font-size: 12px;
        background-color: #f7f9fb;
        border-radius: 100%;
        &:hover {
          color: #316ef5;
        }
      }
      &.add {
        height: 32px;
        margin-left: -24px;
        color: #316ef5;
        i {
          margin: 1px 4px 0 0;
          font-size: 12px;
        }
      }
    }
  }
}
.cascade-confirm-pop {
  .ant-popover-message-title {
    padding-left: 0;
  }
}
.cascade-setting-modal {
  .ant-modal-body {
    padding: 24px 0 24px 24px !important;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;