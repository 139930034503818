body,
div,
// span, // quill blot 斜体
header,
footer,
section,
aside,
article,
ul,
dl,
dt,
dd,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
i,
b,
// em, // quill 斜体
textarea,
button,
input,
select {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  text-decoration: none;
  list-style: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:focus {
    outline: none;
  }
}

body,
header,
footer,
section,
aside,
article,
ul,
dl,
dt,
dd,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
i,
b,
em,
textarea,
button,
input,
select {
  border: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  border-width: 0;
  border-style: solid;
}

html {
  box-sizing: border-box;
}

img {
  -webkit-touch-callout: none;
}

a {
  -webkit-touch-callout: none;
}

a,
a:active,
a:hover {
  text-decoration: none;
}
input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'] {
  -webkit-appearance: none;
}
input,
select,
textarea {
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}
canvas {
  display: block;
}
textarea {
  -webkit-appearance: none;
}
a,
button,
input,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
img,
a {
  -webkit-touch-callout: none;
}
