.CardContentStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .CardContentStatNum {
    color: #316ef5;
    font-weight: 600;
    font-size: 50px;
    line-height: 70px;
    background-clip: text;
    cursor: pointer;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .CardContentStatDesc {
    color: var(--statistic-font);
    font-weight: 600;
    font-size: 16px;
  }
}
.CardContentStatByGroup {
  position: relative;
  width: 100%;
  height: 100%;

  .CardContentStatByGroupInner {
    width: 100%;
    height: 100%;
    padding: 12px 24px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }

  .PrevBtn,
  .NextBtn {
    position: absolute;
    top: 5%;
    z-index: 1;
    width: 30px;
    height: 90%;
    cursor: pointer;
  }
  .PrevBtn {
    left: 0;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-right: 8px solid #d8d8d8;
      border-bottom: 6px solid transparent;
      transform: translate(-50%, -50%);
      content: '';
    }
    &:hover::after {
      border-right: 8px solid #7e7e7e;
    }
  }
  .NextBtn {
    right: 0;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 8px solid #d8d8d8;
      transform: translate(-50%, -50%);
      content: '';
    }
    &:hover::after {
      border-left: 8px solid #7e7e7e;
    }
  }

  .BoxWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 100%;
    margin: 0 auto;
  }

  .BoxLine {
    display: flex;
    align-items: center;
    width: max-content;
  }
  .Box {
    padding: 6px;
  }
  .BoxInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #d4e5fd;
    border-radius: 16px;
    cursor: pointer;
    .BoxNum {
      color: #242e41;
      font-weight: 600;
      font-size: 24px;
    }
    .BoxDesc {
      width: 100%;
      padding: 0 10px;
      color: #242e41;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;