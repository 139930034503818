.sys-tag-select {
  .ant-input-affix-wrapper {
    width: 260px;
    height: 32px;
    margin: 4px 20px;
    background-color: #f8f9fb;
    border-radius: 8px;
    i {
      color: #c0c7d3;
    }
  }
  .tag-list {
    max-height: 300px;
    margin: 0 8px;
    padding-top: 10px;
    overflow-y: auto;
    &-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      padding: 8px 12px;
      border-radius: 8px;
      &:hover {
        background-color: #f8f9fb;
        .tag-list-item-btns {
          visibility: visible;
        }
      }
      &-tag {
        max-width: 200px;
        height: 24px;
        padding: 0 8px;
        color: #316ef5;
        line-height: 24px;
        background-color: #eaf0fe;
        border-radius: 6px;
        cursor: pointer;
        &.no-btn {
          max-width: 260px;
        }
      }
      .ant-input {
        width: 100%;
      }
      &-btns {
        display: flex;
        width: 50px;
        margin-left: 10px;
        color: #ced0d3;
        font-size: 16px;
        visibility: hidden;
        i + i {
          margin-left: 18px;
        }
        i {
          cursor: pointer;
          &:hover {
            color: #316ef5;
          }
        }
      }
    }
  }
  .tag-tip {
    display: flex;
    align-items: center;
    height: 40px;
    margin: 0 20px;
    color: #bfc6d2;
    .ant-checkbox-wrapper {
      margin-right: 8px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;