.viewItem {
  position: relative;
  height: 66px;
  padding: 13px 13px 13px 48px;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fb;
  }

  .viewItemIcon {
    position: absolute;
    top: 13px;
    left: 12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 12px;
  }
  .viewItemName {
    width: 100%;
    color: #242e41;
    font-size: 15px;
    line-height: 22px;
  }
  .viewItemPath {
    margin-top: 2px;
    color: #b6b8b9;
    font-size: 12px;
  }
}
.viewListPlaceholder {
  width: 100%;
  color: #b9bbbc;
  font-size: 16px;
  text-align: center;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;