.structureAcl {
  :global {
    .ant-tree-switcher {
      color: #d2d5da;
      display: flex;
      align-items: center;
    }

    .ant-tree-treenode {
      margin-bottom: 6px;
    }
  }
}

.departmentNode {
  display: flex;
  height: 50px;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 400px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;