// 表单外层容器
.style1Prop {
  display: flex;
  width: 33.3% !important;
  margin: 7px 0 !important;

  .ant-input-number-group-addon {
    background-color: white;
    border: none;
  }
}

// 存在布局信息的时候
.style1PropLayout {
  display: flex;

  .ant-input-number-group-addon {
    background-color: white;
    border: none;
  }
}

.style1PropName {
  display: flex;
  justify-content: flex-end;
  max-width: calc(100% - 180px);
  padding-left: 8px !important;
  color: #777777;
  font-size: 12px;
  background-color: #fff !important;
}

.style1PropValue {
  flex: 1;
  margin-right: 6px !important;
  background-color: transparent !important;
  border-bottom: 1px solid #ebedf0;

  .prop-item-value-label {
    padding: 0 !important;
  }
}

.style1PropValueSelected,
.style2PropValueSelected,
.style3PropValueSelected {
  .ant-picker,
  .ant-input,
  .ant-input-affix-wrapper,
  .edit-input,
  .ant-select-selector {
    font-size: 14px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    ::placeholder {
      font-size: 14px;
    }
  }
}

.prop-item-value-label-style1 {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 6px;
  font-size: 14px;
}

.style1PropText {
  margin-right: 6px;
  color: #242d3f;
  font-weight: 400;
  font-size: 14px;
}

// 第三种样式
.style2Prop {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 33.3% !important;
  height: 52px !important;
  margin: 7px 0 !important;
  padding: 0 6px;

  .ant-input-number-group-addon {
    background-color: white;
    border: none;
  }
}
.style2PropLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  height: 71px !important;
  padding: 0 12px;

  .ant-input-number-group-addon {
    background-color: white;
    border: none;
  }
}
.style2PropName {
  display: flex;
  align-items: center;
  width: 100% !important;
  height: 31px !important;
  padding: 0 !important;
  font-size: 14px;
  background-color: transparent !important;
}
.style2PropValue {
  width: 100% !important;
  height: 40px !important;
  border: 1px solid #ebedf0;
  border-radius: 8px !important;
  &:hover {
    border: 1px solid #316ef5;
  }
}

.prop-item-value-label-style2 {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 8px;
  font-size: 14px;
}
.style2PropText {
  margin-right: 6px;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
}

.propValueLabel {
  .iconattribute_quote,
  .iconhistorical_state,
  .iconma,
  .iconNav_Help,
  .iconfuzhi1,
  .iconshuxingchaifen {
    color: #9da7b8;
    font-size: 14px !important;
    cursor: pointer;
    &:hover {
      color: rgb(49, 110, 245);
    }
  }
}

.groupNameStyle1,
.groupNameStyle3 {
  margin: 6px 0 6px 0;
  padding: 2px 0 2px 12px;
  color: #242d3f;
  font-weight: bold;
  font-size: 12px;
  background-color: rgba(49, 110, 245, 0.1);
  border-radius: 4px;
}
.groupNameStyle3 {
  margin: 6px 0 24px 0;
}

.groupNameStyle2 {
  padding: 0 0 12px 12px;
  color: #242d3f;
  font-weight: 600;
  font-size: 14px;
}

.style2AttrNameIcon {
  color: #9da7b8;
  cursor: pointer;
  &:hover {
    color: rgb(49, 110, 245);
  }
}

// 左右框样式
.style3Prop {
  display: flex;
  width: 33.3% !important;
  margin: 7px 0 !important;

  .ant-input-number-group-addon {
    background-color: white;
    border: none;
  }
}

// 存在布局信息的时候
.style3PropLayout {
  display: flex;
  height: auto !important;

  .ant-input-number-group-addon {
    background-color: white;
    border: none;
  }
}

.style3PropName {
  display: flex;
  justify-content: flex-end;
  max-width: calc(100% - 180px);
  padding-left: 8px !important;
  color: #777777;
  font-size: 12px;
  background-color: transparent !important;
}

.style3PropValue {
  flex: 1;

  height: 40px !important;
  margin-right: 6px !important;
  // background-color: #fff !important;
  border: 1px solid #ebedf0;
  border-radius: 8px !important;

  .prop-item-value-label {
    padding: 0 !important;
  }

  .ant-spin-nested-loading {
    flex: 1;
  }
}

.prop-item-value-label-style3 {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 6px;
  font-size: 14px;
}

.style3PropText {
  margin-right: 6px;
  color: #242d3f;
  font-weight: 400;
  font-size: 14px;
}

// 适配文字换行样式
.textWrapContainer {
  .prop-item {
    align-items: stretch;
    height: auto;
  }

  .prop-item-value {
    height: auto;
  }

  .style2PropValue,
  .style3PropValue {
    height: auto !important;
    min-height: 40px;
  }

  .style1PropValue {
    min-height: 32px;
  }

  .valueWrapSpan {
    word-break: break-all;
  }

  .style2PropLayout {
    height: auto !important;
  }

  .style1PropName {
    align-items: flex-start;
    padding-top: 5px;
    background-color: transparent !important;
  }

  .style3PropName {
    align-items: flex-start;
    padding-top: 10px;
  }

  .prop-item-name {
    height: auto;
  }
  .prop-item-value-label {
    height: auto;
    padding: 6px 0 6px 16px;
    line-height: 20px;
  }
}

// darkness css
#darknessProps {
  .groupNameStyle2 {
    color: #59627e !important;
  }
  .style2PropName,
  .style3PropName,
  .style1PropName {
    background-color: transparent !important;
  }
  .defaultPropName {
    background: rgba(199, 212, 255, 0.05) !important;
  }

  .style1PropNameText,
  .style2PropNameText,
  .style3PropNameText,
  .defaultPropNameText {
    color: #919ab5 !important;
  }

  .style2PropValue,
  .style3PropValue {
    background-color: transparent !important;

    border: 1px solid rgba(81, 88, 111, 0.5) !important;
  }
  .style1PropValue {
    background-color: transparent !important;

    border-bottom: 1px solid rgba(81, 88, 111, 0.5) !important;
  }
  .defaultPropValue {
    background: rgba(199, 212, 255, 0.05) !important;
  }

  .placeHolderText {
    color: #535b70 !important;
  }
  .style2PropText,
  .style3PropText,
  .style1PropText,
  .defaultPropText {
    color: white !important;
  }

  .prop-setting {
    background: rgba(199, 212, 255, 0.05) !important;
    border: none !important;
    border-radius: 16px !important;
    .prop-setting-add {
      color: #59627e;
      .iconButton_add {
        color: #59627e !important;
      }
      &:hover {
        color: #316ef5 !important;
        .iconButton_add {
          color: #316ef5 !important;
        }
      }
    }
    .propVisibleToggle,
    .prop-setting-collapse {
      color: #59627e !important;
      &:hover {
        color: #316ef5 !important;
      }
    }
    .prop-setting-collapse {
      .iconattribute_fold {
        color: #59627e !important;
      }
      &:hover {
        .iconattribute_fold {
          color: #316ef5 !important;
        }
      }
    }
  }
  .defaultPropSetting {
    border-radius: 0 0 8px 8px !important;
  }
  .iconNav_Help {
    color: rgba(62, 112, 223, 0.5) !important;
  }

  .disable-prop {
    background: rgba(81, 88, 111, 0.102) !important;
    .placeHolderText {
      color: white !important;
    }
  }
  .propValueLabel {
    .iconattribute_quote {
      color: rgba(62, 112, 223, 0.5) !important;
      background: transparent !important;
      &:hover {
        color: rgb(49, 110, 245) !important;
      }
    }
  }
  .iconhistorical_state,
  .iconma,
  .iconfuzhi1,
  .iconshuxingchaifen {
    color: #919ab5 !important;
    background: transparent !important;
    &:hover {
      color: rgb(49, 110, 245) !important;
    }
  }
  .groupNameStyle3,
  .groupNameStyle1 {
    color: #6890eb !important;

    background: rgba(104, 144, 235, 0.2) !important;
  }
  .groupNameDefault {
    color: #cacfde !important;
    ::after {
      background-color: #97ff2f;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;