@import '~@/mixin.less';

.status-process-container {
  display: flex;
}

.status-item {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  margin-right: 2px;
  overflow: hidden;
  background-color: #f1f1f3;
  border-radius: 2px 20px 20px 2px;
  cursor: pointer;

  &:not(:first-child) {
    // mask: radial-gradient(22px 22px at -12px, #0000 100%, #000) 0;
    // mask-size: 100%;
    // mask-repeat: no-repeat;
    mask-image: url('~@/assets/img/status_transparent_tail.png'),
      linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: 0%, 30px 100%;
  }

  .font-normal(12px, #fff);

  &.future {
    color: #767c88;
  }

  &-icon {
    margin-right: 12px;
    font-size: 14px;
  }

  &-title {
    padding: 0 16px;
  }

  // &-tail {
  //   width: 9px;
  //   height: 40px;
  //   background: center / contain no-repeat url('~@/assets/img/status_tail.png');
  // }

  // &-tail {
  //   position: absolute;
  //   left: -42px;
  //   width: 50px;
  //   height: 50px;
  //   background-color: var(--bg);
  //   border-radius: 50%;
  // }
}

.status-pop {
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner-content {
      padding: 12px;
    }
  }
}

.status-pop-content {
  min-width: 180px;
  max-width: 400px;

  .pop {
    &-title {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px dashed #ebedf0;
    }

    &-icon {
      margin-right: 4px;
    }

    &-content {
      padding-top: 10px;

      &-item {
        display: flex;
        margin-bottom: 9px;

        &:last-child {
          margin: 0;
        }

        &-label {
          .font-normal(12px, #767c88);
        }

        &-value {
          display: flex;
          flex-wrap: wrap;
          .font-normal(12px, #242d3f);
        }
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;