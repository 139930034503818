.fontColor {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  border: 1px solid #ebedf0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  .fontColorSlide {
    position: absolute;
    bottom: 6px;
    width: 20px;
    height: 6px;
    border: 1px solid #ebedf0;
    border-radius: 20px;
  }
}

.textDecoration {
  margin-left: 8px;
  height: 32px;
  width: 96px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ebedf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
}

.decoration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  width: 27px;
  cursor: pointer;
  border-radius: 8px;
}

.treeNode {
  .edit {
    display: none;
    cursor: pointer;
    margin-right: 16px;
  }

  .del {
    display: none;
    cursor: pointer;
  }

  :global {
    .ant-input {
      padding-left: 4px !important;
      background-color: transparent;
    }
  }
  &:hover {
    // background: rgba(49, 110, 245, 0.1) !important;
    .edit,
    .del {
      display: block;
    }
  }
}

.popover,
.childPopover {
  left: -32px !important;

  :global {
    .ant-popover-inner-content {
      padding: 0;
      overflow: hidden;
      border-radius: 8px 8px 0 0;
      top: 0px;
      width: 296px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}
.childPopover {
  left: -56px !important;
}
.container {
  :global {
    .ant-tree-switcher {
      display: none;
    }
    .ant-tree-node-content-wrapper {
      padding: 0 !important;
    }
    .ant-tree-treenode {
      background: #f8f9fb;
      margin-top: 12px;
      height: 40px;
      align-items: center !important;
      padding: 0 12px 0 8px !important;
      border-radius: 8px;
      &:hover {
        background: rgba(49, 110, 245, 0.1) !important;
        :global {
          .ant-tree-node-content-wrapper {
            background: transparent !important;
          }
        }
      }
    }
  }
}

.emptyContainer {
  :global {
    .ant-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;