.locationSelectModal {
  padding: 0;
  overflow: hidden;
}
.locationSelect {
  display: flex;
  height: 600px;
  background: #fff;
  border-radius: 10px;
}

.search {
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: #fff;
  border-right: 1px solid #ebedf0;
  .searchInput {
    padding: 16px 16px 0 16px;
    .input {
      background-color: #f8f9fb;
      border-radius: 8px;
    }
  }
  .addrList {
    flex: 1;
    height: 0;
    overflow-y: auto;
    .addrListItem {
      position: relative;
      padding: 12px 30px 12px 16px;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: rgba(49, 110, 245, 0.05);
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background-color: #ebedf0;
        content: '';
      }
      .addrListItemName {
        color: #242d3f;
        .highlight {
          color: #316ef5;
        }
      }
      .addrListItemAddr {
        color: #767c88;
      }
      .selectIcon {
        position: absolute;
        right: 16px;
        bottom: 12px;
        display: none;
        color: #316ef5;
        font-size: 14px;
      }
      &.curItem {
        .addrListItemName {
          font-weight: bold;
        }
        .selectIcon {
          display: block;
        }
      }
    }
  }
  .saveBtns {
    padding: 16px;
    text-align: right;
  }
}
.map {
  flex: 1;
}

// map editor
.mapEditor {
  position: relative;
  width: 100%;
  height: 100%;
  .mapContainer {
    width: 100%;
    height: 100%;
  }
  .overlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    .locationCenter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .locationCenterImg {
      width: 32px;
      margin-top: -22%;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;