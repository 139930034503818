.form {
  :global {
    .ant-form-item-label {
      color: #767c88;
    }
    .ant-form-item-label
      > label:not(.ant-form-item-required):not(.ant-form-item-required-mark-optional)::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      opacity: 0;
      content: '*';
    }

    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector,
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer).ant-select-open
      .ant-select-selector,
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer).ant-select-focused
      .ant-select-selector,
    .ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input,
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number,
    .ant-picker,
    .ant-input {
      background: #f8f9fb;
      border: 0;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;