.panelWrapper {
  margin-bottom: 20px;
}

.panel {
}

.panelHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 8px;
  color: #b6b8b9;
  font-size: 14px;
  .clearBtn {
    padding: 4px 8px;
    color: #6b7a96;
    border-radius: 6px;
    cursor: pointer;
  }
}

.panelBody {
}

.panelFooter {
  font-size: 14px;
  :global {
    .ant-btn-link {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.conditionItem {
  display: flex;
  .field {
    width: 130px;
    margin-right: 8px;
  }

  .operator {
    width: 100px;
    margin-right: 8px;
    line-height: 32px;
  }

  .conditionValueList {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 0;

    .conditionValueItem {
      position: relative;
      display: flex;
      width: 100%;
      margin-bottom: 8px;
      :global {
        .ant-picker {
          border-radius: 8px;
        }
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 4px 0 0 8px;
    background-color: #f7f9fb;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    :global {
      .iconfont {
        color: #6b7a96;
        font-size: 12px;
      }
    }
  }
}

.hiddenPicker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  visibility: hidden;
}

.nodeSelect {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  .nodeSelectRow {
    position: relative;
    display: flex;
    width: 100%;
    & + .nodeSelectRow {
      margin-top: 8px;
    }
  }
}

condition-filter-v2-value-editor {
  :global {
    .ant-picker,
    .ant-select,
    .ant-input-number,
    .ant-input-affix-wrapper,
    .ant-select-selector {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;