.content {
  position: relative;
  display: flex;
  height: 600px;
  overflow: hidden;
  background-color: #f7f8fa;
  border-radius: 8px;
  &::before {
    position: absolute;
    top: 106px;
    left: 50%;
    width: 1px;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23EBEEF7FF' stroke-width='2' stroke-dasharray='1%2c 10' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    content: ' ';
  }
  .arrow {
    position: absolute;
    top: 194px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 24px;
    box-shadow: 0 2px 4px 0 rgba(107, 122, 150, 0.2);
    transform: translateX(-50%);
    :global(.iconfont) {
      color: #242d3f;
      font-size: 24px;
      line-height: 1;
      transform: translateX(2px);
    }
  }
}

.save {
  display: flex;
  justify-content: center;
  :global {
    .ant-btn {
      width: 144px;
      transform: translateY(-50%);
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;