.EditSortIndexButtonPopover {
  // width: 288px;
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-placement-bottom {
      padding: 0;
    }
    .ant-popover-inner-content {
      padding: 16px;
    }
  }
}
.indexCard {
  height: 32px;
  display: flex;
  align-items: center;
  width: 256px;
  padding: 0 8px;
  background-color: #f8f9fb;
  border-radius: 8px;
  margin-bottom: 8px;
  .name {
    flex: 1;
    margin-left: 8px;
    font-size: 14px;
    color: #242d3f;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;