.btn {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  padding: 4px 15px;
  color: white;
  background-color: #316ef5;
  border-radius: 4px;
  cursor: pointer;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;