#QuoteFilter {
  .condition-item {
    display: flex;
    //align-items: center;
    width: 100%;
    margin-bottom: 8px;
    line-height: 32px;
    .ant-select {
      width: 100%;
    }
    &-key {
      width: calc(35% - 54px);
      margin-right: 8px;
    }
    &-symbol {
      width: 100px;
      margin-right: 4px;
    }
    &-positioning {
      display: flex;
      width: calc(65% + 26px);
      height: 32px;
      margin-right: 4px;
      &-map {
        display: flex;
        justify-content: space-between;
        width: 160px;
        margin: 0 6px;
        padding-left: 11px;
        line-height: 32px;
        border: 1px solid #ebedf0;
        border-radius: 8px;
        &-name {
          width: 118px;
        }
        &.null {
          color: #d9d9d9;
        }
        i {
          margin: 0 !important;
          padding-right: 11px;
          color: #909bb0;
          font-size: 12px;
          border: none !important;
          cursor: pointer;
          &:hover {
            color: #316ef5;
          }
        }
      }
      &-distance {
        width: 122px;
        margin-right: 6px;
      }
    }
    &-null {
      width: calc(65% + 26px);
      height: 32px;
      margin-right: 4px;
      padding: 0 11px;
      color: #d9d9d9;
      line-height: 32px;
      border: 1px solid #ebedf0;
      border-radius: 8px;
      cursor: not-allowed;
    }
    &-value {
      width: calc(65% - 82px);
      margin-right: 8px;
      line-height: 32px;
      &.hasAddAfter {
        width: calc(65% - 122px);
      }
      .ant-input-number-group-wrapper {
        width: 100%;
        .ant-input-number-group-addon {
          padding: 0;
          border-color: #ebedf0;
          border-radius: 8px 0 0 8px;
          .ant-select-selector {
            height: 30px;
          }
        }
      }
      .ant-input-number {
        width: 100%;
      }
      .ant-input {
        margin-bottom: 4px;
      }
      .ant-picker {
        width: 100%;
      }
    }
    .iconButton_add {
      height: 32px;
      margin-right: 8px;
      padding: 0 9px;
      color: #909bb0;
      font-size: 12px;
      border: 1px dashed #cad0d8;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        color: #316ef5;
        border-color: #316ef5;
      }
    }
    .icona-zujian67 {
      cursor: pointer;
      &:hover {
        color: #f0665e;
      }
    }
  }
  .condition-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    color: #6b7a96;
    border: 1px dashed #dddee0;
    border-radius: 8px;
    cursor: pointer;
    i {
      margin-right: 4px;
      font-size: 12px;
    }
  }
}

.quote-filter-pop {
  padding: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    width: 160px;
    border-radius: 6px;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.1);
  }
  .ant-popover-inner-content {
    padding: 6px 0;
    .ant-radio-group {
      padding: 6px 0 12px 13px;
      border-bottom: 1px solid #e5e5e5;
    }
  }
  &-prop {
    color: #242d3f;
    line-height: 40px;

    &-description {
      padding: 0 13px;
      color: #bfc6d2;
    }

    &-item {
      padding: 0 13px;
      //border-radius: 8px;
      cursor: pointer;
      &.active {
        background: #eaf0fe;
      }
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}

.condition-range-picker {
  .view-range-picker-extra {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
    color: #242e41;
    .extra-title {
      flex-shrink: 0;
      margin: 8px 8px 0 0;
      color: #242e41;
      line-height: 24px;
      border-radius: 8px;
      cursor: pointer;

      &.btn {
        padding: 0 8px;
        color: #316ef5;
        background: #f0f7ff;
        border-color: #adcfff;
      }

      &.active {
        color: #fff;
        background: #316ef5;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;