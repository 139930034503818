.web-office-modal {
  :global {
    .ant-modal-body {
      //margin-top: 32px;
      padding: 0 !important;
    }
    .ant-modal-content {
      overflow: hidden;
    }
  }
  iframe {
    border: 0;
    box-shadow: none !important;
  }
}
.pdfWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
}
.pdfPagination {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  text-align: center;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;