.panel {
  overflow-x: hidden;
  background-color: white;
  user-select: none;
}

.wrapper {
  position: relative;
  min-width: 32px;
  background-color: white;

  &.right {
    border-left: 1px solid #ebedf0;
  }
  &.left {
    border-right: 1px solid #ebedf0;
  }
}

.content {
  position: relative;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  user-select: none;
  z-index: 2;
  &.open {
    background: #4d639213;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(77, 99, 146, 0.1);
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;