.forModelView {
  &:focus {
    color: #98a2b6;
  }

  &:not(.darkBtn):hover {
    color: #98a2b6;
    background-color: #f7f9fb !important;
  }

  color: #98a2b6;
  font-size: 12px !important;
  border-radius: 8px;

  &.darkBtn {
    background-color: transparent;
    &:hover {
      color: #98a2b6;
      background-color: rgba(248, 249, 251, 0.05) !important;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;