.wrapper {
  padding: 12px;
  background-color: #fafafa;
  border-radius: 8px;
}

.addType {
  :global {
    .ant-radio-wrapper {
      margin-right: 0;
    }

    span.ant-radio + * {
      padding-right: 0;
    }
  }
}

.displayAttrs {
  padding-top: 0px;
}

.attr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.attrName {
  width: 150px;
  margin-left: 8px;
  overflow: hidden;
  color: #242d3f;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mainRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  font-weight: 700;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;