.setCollapse {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  color: #98a2b6;
  font-size: 12px;
  white-space: nowrap;
  background-color: #f7f9fb;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #316ef5;
    background-color: #f4f7fd;
  }
}

.darkBlock {
  color: #98a2b6 !important;
  background-color: rgba(248, 249, 251, 0.05);
  &:hover {
    background-color: rgba(248, 249, 251, 0.05);
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;