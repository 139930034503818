.wrapper {
  color: #242e41;
  font-size: 14px;
  width: 200px;

  :global {
    svg,
    .iconfont {
      color: #95a0b4;
      font-size: 14px;
    }

    .ant-dropdown-menu-title-content > * {
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px !important;
      background-color: unset;
    }
    .link-pi-btn > i {
      margin-right: 8px !important;
    }
    .ant-input-affix-wrapper,
    .ant-btn {
      padding: 0;
    }
    .ant-dropdown-menu-item-group-title {
      margin-top: 4px;
      color: #b7bac0;
      font-size: 14px;
    }
    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }
    .view-setting-block {
      justify-content: flex-start;
      height: unset;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      width: 100%;
      text-align: left;

      i {
        margin-right: 8px;
      }
    }
  }
}

.popover {
  :global {
    .ant-popover-content {
      width: 180px;
    }
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner-content {
      padding: 12px 16px 12px 16px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;