@import '~antd/es/style/themes/default.less';
.flowOption {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  line-height: 30px;
  border: 1px solid #ebedf0;
  border-radius: 4px;
  .flow-select {
    .flow-text {
      display: flex;
      color: #242d3f;
      &-unset {
        color: #bdc0c5;
      }
    }
    &-unset {
      color: #bdc0c5;
    }
  }
}
.formOptions {
  width: 200px;
  height: 160px;
  padding: 9px 0 6px 16px;
  .iconxiala {
    font-size: 16px;
    transform: scale(0.6) rotate(-90deg);
  }
  .iconjiantou {
    font-size: 14px;
  }
  i {
    margin-right: 12px;
    font-size: 12px;
  }
  .formStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 22px;
    color: #bdc0c5;
    line-height: 22px;
    cursor: pointer;
    .form-label {
      display: flex;
    }
    .form-option-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .option-labels {
        display: flex;
        flex-direction: row-reverse;
        width: 114px;
      }
      .option-label {
        width: 52px;
        height: 24px;
        margin-left: 8px;
        padding: 0 4px;
        color: #fff;
        line-height: 24px;
        text-align: center;
        background-color: #94b1f4;
        border-radius: 4px;
      }
    }
    .label-active {
      color: #242d3f;
    }
  }
  .form-status {
    height: 40px;
    line-height: 40px;
  }
}
.flowPopover {
  padding: 2px;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.1);
  }
  .ant-popover-inner-content {
    padding: 6px 0;
  }
  .status-option {
    width: 152px;
    height: 40px;
    padding: 0 16px;
    color: #242d3f;
    line-height: 40px;
    cursor: pointer;
    &:hover {
      background-color: #f4f7fd;
    }
  }
  .status-selected {
    background-color: #f4f7fd;
    cursor: default;
  }
  .status-disabled {
    color: #bdc0c5;
    cursor: not-allowed;
  }
  .operator-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 152px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    &-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 76px;
    }
    &-options {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 76px;
      height: 200px;
      overflow-y: auto;
      &-item {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background-color: #f4f7fd;
        }
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;