.CascaderSelect {
  :global {
    li.ant-cascader-menu-item:has(
        .ant-cascader-menu-item-content > .pi-cascader-label-disabled
      )
      > .ant-cascader-checkbox {
      display: none;
    }

    li.ant-cascader-menu-item[aria-checked='true']:not(
        .ant-cascader-menu-item-active
      ) {
      background: #5c5c5c0f;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;