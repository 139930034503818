.form {
  :global {
    .condition-filter-item-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .condition-filter-value-item-btn {
        display: none;
      }
      > div {
        flex: 0 0 calc(calc(100% - 16px) / 3);
      }
    }

    .condition-filter-item {
      background-color: white;
      border-radius: 8px;
    }

    .condition-filter-item-disabled {
      background-color: #f5f5f5;
    }

    .condition-filter-item-operator {
      position: relative;
      margin-right: 8px;
      padding-right: 4px;
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 40%;
        border-right: 1px solid #e5e7eb;
        transform: translateY(-50%);
        content: '';
        pointer-events: none;
      }
    }

    .condition-filter-item-field {
      position: relative;
      margin-right: 0;
      pointer-events: none;
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 40%;
        border-right: 1px solid #e5e7eb;
        transform: translateY(-50%);
        content: '';
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        padding-right: 0;
        background-color: unset;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        display: inline-flex;
        align-items: center;
        padding: 0;
        color: rgba(153, 153, 153, 0.9);
      }
    }

    .condition-filter-value-item {
      height: 100%;
      margin: 0 !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select {
      height: 100%;
      border: 0;
      box-shadow: none;
    }

    .ant-select-multiple .ant-select-selection-item {
      background-color: #eff4ff;
      border-color: #eff4ff;
    }

    i.iconfont.iconuser {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      padding: 0 !important;
      font-size: 12px !important;
    }

    .ant-avatar {
      width: 18px !important;
      height: 18px !important;
      margin-bottom: 2px;
    }

    span.anticon.anticon-close {
      color: #999;
    }

    .ant-input-number,
    .flowOption,
    .ant-input-affix-wrapper,
    .ant-input {
      height: 100%;
      border: 0;
      box-shadow: none;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: unset;
    }

    .ant-picker {
      height: 100%;
      border: 0;
    }
  }
}

.form.collapse {
  visibility: unset;
  :global {
    .condition-filter-item:nth-of-type(1n + 10) {
      display: none;
    }
  }
}

.wrapper {
  padding: 8px;
  background: #f9fafc;
  border-radius: 8px;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;