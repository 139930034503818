.content {
  display: inline-block;
  width: 180px;
  height: 36px;
  overflow: hidden;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  cursor: pointer;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;