.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 16px;
  padding: 0 8px;
  color: #242d3f;
  font-weight: bold;
  font-size: 12px;
  :global {
    .ant-btn-icon-only {
      width: 24px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 16px;
}
.content {
  flex: auto;
  height: 0;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;