.modal-custom-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  gap: 8px;
}

.custom-button-wrapper {
  cursor: pointer;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;