.node-import-modal {
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #316ef5;
    background-color: #eaf0fe;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
  .ant-radio-button-wrapper {
    color: #242e41;
    font-weight: 600;
    font-size: 14px;
    background-color: #f7f9fb;
    border: none;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }
  .ant-modal-title {
    color: #1e1f21;
    font-weight: 500;
    font-size: 18px;
  }
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
  .import-hidden {
    display: none;
  }
  .import-exist {
    margin-bottom: 8px;
  }
  .import-description {
    color: #1e1f21;
    font-size: 14px;
    line-height: 20px;
  }
  .import-tips {
    margin: 8px 0 0 0;
    color: #b6b8b9;
    font-size: 14px;
    line-height: 20px;
    .anticon {
      color: #6b7a96;
      &:hover {
        color: #316ef5;
      }
    }
  }
  .export-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
    height: 32px;
    margin-top: 10px;
    color: #316ef5;
    background-color: #f7f9fb;
    border-radius: 6px;
    cursor: pointer;
    i {
      margin-right: 8px;
    }
  }
  .import-download {
    width: 138px;
    margin-bottom: 8px;
    color: #242d3f;
    font-size: 14px;
    i {
      color: #242d3f;
      font-size: 16px;
    }
  }
  .import-header-error {
    height: 24px;
    margin-top: -24px;
    color: #ff4d4f;
  }
  .template-import {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
  }
  .ant-upload-drag {
    height: 184px;
    background: #fff !important;
    border: 1px dashed #ebedf0;
    border-radius: 4px;
    .ant-upload-drag-icon {
      margin-bottom: 4px !important;
    }
    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-upload-btn {
      padding: 36px 0;
    }
    p {
      color: #242e41;
    }
    .upload-hint {
      color: #b6b8b9;
      //margin-top: 8px;
      &-button,
      .anticon {
        color: #316ef5;
      }
      .anticon {
        margin-left: 4px;
      }
      &.illustration {
        width: 80px;
        margin-top: 16px;
        color: #1e1f21;
      }
    }
  }
  .ant-modal-footer {
    border: none;
    button {
      margin: 0 8px 0 16px;
    }
  }
  .import-err-modal {
    .ant-modal-header {
      border: none;
      .err-title {
        display: flex;
        align-items: center;
        color: #1e1f21;
        font-weight: 500;
        font-size: 16px;
        &-icon {
          margin-right: 12px;
          color: #f0665e;
          font-size: 20px;
        }
      }
    }
    .ant-modal-footer {
      padding: 40px 24px 32px 0;
    }
  }
  .import-footer {
    position: absolute;
    right: 0;
    bottom: 8px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .footer-description {
      color: #b6b8b9;
      font-size: 14px;
    }
    .footer-buttons {
      .footer-btn {
        min-width: 62px;
        margin-left: 24px;
      }
    }
  }

  .searchInput {
    background-color: #f8f9fb !important;
    border: none !important;
    border-radius: 8px !important;
    outline: none !important;
    box-shadow: none !important;
    :global {
      .ant-input {
        background-color: #f8f9fb;
      }

      .anticon-search {
        color: #bfc6d2;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;