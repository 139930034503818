.address-pop {
  margin: 6px 16px 6px 16px;
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .iconButton_cancel {
      margin-left: 8px;
      padding: 2px 6px;
      color: rgba(0, 0, 0, 0.25);
      background-color: #f8f9fb;
      border-radius: 100%;
      cursor: pointer;
      &:hover {
        background: #f0f6ff;
      }
    }
  }
  &-tab {
    display: flex;
    margin-bottom: 16px;
    color: #b6b8b9;
    font-size: 14px;
    &-item {
      margin-right: 24px;
      cursor: pointer;
      &.active,
      &:hover {
        color: #316ef5;
      }
      &.active {
        border-bottom: 3px solid #316ef5;
      }
    }
  }
  &-search {
    &-item {
      padding: 0 12px;
      line-height: 32px;
      border-radius: 8px;
      cursor: pointer;
      &:hover,
      &.active {
        background: #f0f6ff;
      }
    }
  }
  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 296px;
    color: #b6b8b9;
  }
  &-add {
    padding: 20px 4px 0 0;
  }
  &-area {
    display: flex;
    &-ul {
      &.bordered {
        border-right: 1px solid #e5e7eb;
      }
      &-header {
        margin-bottom: 8px;
        padding-left: 20px;
        color: #767c88;
        line-height: 32px;
        background: #f8f9fb;
        &.left {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &.right {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        &.only {
          border-radius: 4px;
        }
      }
      &-list {
        height: 256px;
        line-height: 32px;
        //overflow-y: auto;
        &-item {
          margin: 0 8px;
          padding: 0 12px;
          border-radius: 8px;
          cursor: pointer;
          &:hover,
          &.active {
            background: #f0f6ff;
          }
        }
      }
    }
  }
  &-quote {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    color: #bfc6d2;
    border-top: 1px solid #f8f9fb;
    cursor: pointer;
    i {
      margin-right: 6px;
    }
    &-name {
      margin: 0 4px;
      padding: 5px;
      background-color: #f8f9fb;
      border-radius: 8px;
    }
  }
}
.address-input-display {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 8px;
  &-name {
    margin-right: 4px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;