.wrapper {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  .indexCard {
    flex-shrink: 0;
    background: linear-gradient(135deg, #5065f7 0%, #8198ff 100%);
    border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 20px 0;

    &.iconPositionLeft {
      flex-direction: row;
      padding-left: 24px;
    }
    &.iconPositionRight {
      flex-direction: row-reverse;
      padding-right: 24px;
    }

    &.selected:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid #316ef5 !important;
      top: 0;
      left: 0;
      border-radius: 12px;
    }

    .indexIcon {
      img {
        width: 48px;
        height: 48px;
      }
    }
    .subitemWrapper {
      display: flex;
      align-items: center;
      .subitem {
        padding: 0 24px;
        color: #fff;
        position: relative;
        & + .subitem:after {
          position: absolute;
          content: '';
          height: 24px;
          width: 2px;
          left: 0;
          background-color: rgba(255, 255, 255, 0.2);
          top: 50%;
          transform: translateY(-50%);
        }
        .subitemTitle {
          font-size: 16px;
          line-height: 1.5;
          font-weight: 400;
          margin-bottom: 4px;
        }
        .subitemContent {
          display: flex;
          align-items: baseline;
          & > div:nth-child(1) {
            font-size: 24px;
            color: #ffffff;
            line-height: 1.5;
          }
          & > div:nth-child(2) {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            line-height: 1.5;
            margin-left: 16px;
          }
          & > div:nth-child(3) {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            line-height: 1.5;
            margin-left: 8px;
          }
          & > div:nth-child(4) {
            margin-left: 5px;
          }
        }
      }
    }
  }
  &.selectable .indexCard {
    &:hover:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px dashed #316ef5;
      top: 0;
      left: 0;
      border-radius: 12px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;