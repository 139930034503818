.drafts-node-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  // 内容页大模型-弹窗中去除不必要的外边距
  .pi-node-page-model {
    margin: 0;
  }

  .ant-modal {
    top: 0 !important;
    min-width: 1024px;
    height: 90vh;
    padding-bottom: 0;
  }

  .ant-modal-content {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.1);

    .ant-modal-header {
      height: 52px;
      padding: 14px 20px;
      border: none;
      border-radius: 8px 8px 0 0;

      .ant-modal-title {
        .node-modal-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;

          &-des {
            margin-right: 16px;
            color: #b7bac0;
          }

          &-main {
            color: #242e41;
            font-weight: 500;
          }

          &-sub {
            color: #858fa5;
            font-weight: 400;
            font-size: 16px;
          }

          i {
            margin: 2px 4px 0 4px;
            color: #b7bac0;
            font-size: 12px;
          }
        }
      }
    }

    .ant-modal-body {
      display: flex;
      width: 100%;
      height: calc(100% - 124px); // header 52 footer 32 padding 40
      //padding: 40px 192px 0 192px;
      padding: 0;
      color: #242e41;
      font-weight: 400;
      font-size: 14px;
    }

    .ant-modal-footer {
      padding: 20px;
      border: none;
    }

    .ant-modal-close {
      color: #d8d8d8;

      &:hover {
        color: #858fa5;
      }
    }
  }

  .spin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.fullScreen .ant-modal {
  top: 0 !important;
  left: 0 !important;
  margin: 0;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;