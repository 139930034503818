.popover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
      overflow: hidden;
      border-radius: 8px 8px 0 0;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  .exportGraphicCode {
    position: relative;
    height: 25px;
    span {
      position: absolute;
      right: 16px;
      color: #316ef5;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    overflow: hidden;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;