.attr {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  height: 56px;
  padding: 0 16px;
}

.dragContainer {
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #f8f9fb;
  padding: 0 12px;
  margin-top: 12px;
  // justify-content: space-between;
}

.showFontColor {
  position: absolute;
  bottom: 6px;
  width: 20px;
  height: 6px;
  border: 1px solid #ebedf0;
  border-radius: 20px;
}

.textDecoration {
  margin-left: 8px;
  height: 32px;
  width: 96px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ebedf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
}

.setIconPopover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
}

.iconContainer {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 12px;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    .mask {
      display: flex;
    }
  }
}

.container {
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  :global {
    .ant-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;