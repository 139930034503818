.popover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
      overflow: hidden;
      border-radius: 8px 8px 0 0;
    }

    padding-top: 0;

    .ant-popover-arrow {
      display: none;
    }
  }
}

.container {
  min-height: 120px;
  padding: 8px;
  overflow: auto;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  overflow: hidden;
}

.btnPicture {
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 2px;
  background-position: center;
  background-size: cover;
}

.attachment {
  display: inline-block;
  margin-right: 8px;
  background-position: center;
  background-size: cover;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;