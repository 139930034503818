.wrapper {
  position: relative;
  width: 48px;
  height: 48px;
  .inner {
    position: relative;
    width: 48px;
    height: 48px;
  }
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    align-items: center;
    justify-content: center;
  }

  &:hover .mask {
    display: flex;
  }
  :global {
    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;