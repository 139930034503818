@import 'antd/lib/style/themes/default.less';

.condition-filter-v2 {
  .condition-list {
    margin-bottom: 16px;
  }

  .condition-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .condition-item-content {
      flex: 1;
      display: flex;
      align-items: start;
      gap: 8px;
    }

    .ant-btn {
      margin-left: 8px;
    }
  }

  .add-condition-btn {
    color: @primary-color;
    cursor: pointer;

    &:hover {
      color: @primary-5;
    }

    &:active {
      color: @primary-7;
    }

    .anticon {
      margin-right: 4px;
    }
  }
}

.condition-group {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .delete-group {
      margin-left: 16px;
      color: #ff4d4f;
      cursor: pointer;
    }
  }
}

.add-group-btn {
  margin-bottom: 16px;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;