.voltName {
  color: #b6b8b9;
  font-size: 12px;
}

.commit {
  margin-bottom: 0 !important;
  color: #767c88;
  font-size: 13px;
}

.icon {
  position: absolute;
  top: -8px;
  right: -8px;
}

.history {
  height: 30px;
  margin-left: 8px;
  color: #316ef5 !important;
  font-size: 14px;
  line-height: 24px;
  background: #f0f6ff;
  border-radius: 8px !important;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;