.overlayContainer {
  width: 120px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

  .action {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .divider {
    width: 100%;
    padding: 0 12px;

    div {
      height: 1px;
      background-color: #ebedf0;
    }
  }

  .alignsContainer {
    display: flex;
    justify-content: space-between;
    padding: 6px 16px;
  }
}

.active {
  background-color: rgba(49, 110, 245, 0.05);
}

.styleItem {
  &:hover {
    background-color: #f5f5f5;
    .useAll {
      visibility: hidden;
    }
  }

  cursor: pointer;
  .useAll {
    visibility: hidden;
  }
}
.chooseColorPop {
  :global {
    .ant-popover-inner-content {
      padding: 0;
      overflow: hidden;
      border-radius: 8px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;