.statusMenuPop {
  //padding: 0;
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.1);
  }

  .ant-popover-inner-content {
    padding: 6px 0;
  }

  .menu-list {
    width: 200px;
    max-height: 320px;
    overflow: hidden;
    color: #242d3f;

    .menu-disabled {
      color: #b9bbbc;
      cursor: not-allowed;
    }

    .current-item {
      margin-left: 0 !important;
    }

    .menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      margin: 0 4px;
      border-radius: 8px;

      &:hover {
        background-color: #f6f8fc;

        i {
          background: #f6f8fc;
        }

        .edit-btn {
          display: flex;
        }
      }

      .edit-btn {
        display: none;
        color: #6b7a96;

        &:hover {
          color: #316ef5;
        }
      }

      .disabled {
        color: #b6b8b9;
        cursor: not-allowed;
      }

      .normal-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 16px;
        line-height: 20px;

        &.template {
          color: #242d3f;

          i {
            color: #b5bdcb;
          }
        }

        &.status {
          //justify-content: space-between;
          width: calc(100% - 8px);
          height: 40px;
          color: #6b7a96;

          &.add {
            border-top: 1px solid #ebedf0;
          }

          i {
            font-size: 16px;
          }

          &:hover {
            color: #242d3f;
          }
        }
      }

      &.status {
        &:hover {
          background-color: #fff;

          i {
            background: #fff;
          }
        }
      }
    }

    & > li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //height: 40px;
      overflow: hidden;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: #fff;
      cursor: pointer;

      i {
        padding-right: 12px;
        font-size: 18px;
        background: #fff;
      }
    }

    &:hover {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.templateMenuPopover {
  padding: 0;

  .ant-popover-inner {
    border-radius: 6px;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.1);
  }

  .ant-popover-inner-content {
    padding: 6px 0;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.ant-popconfirm {
  .confirm-title {
    color: #242d3f;
    font-size: 14px;
  }

  .confirm-description {
    color: #767c88;
    font-size: 14px;
  }
}

.flowButtonsContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.status_list {
  height: 30px;
  border-radius: 8px 8px 8px 8px;
}

.button-condition-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 4px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .bc-popover-content {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &-item {
      padding: 0 12px;
      display: flex;
      align-items: center;
      height: 40px;
      color: #242d3f;
      font-size: 14px;
      border-bottom: 1px solid #ebedf0;

      &:last-child {
        border-bottom: 0;
      }

      .iconfont {
        margin-right: 8px;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;