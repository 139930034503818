.wrapper {
  padding: 6px 16px;
  font-size: 12px;
  white-space: nowrap;
  background-color: #f7f7f7;
  color: #242d3f;

  &.secondary {
    color: #767c88;
    white-space: pre-wrap;
  }

  &:not(.secondary) {
    font-weight: bold;
    font-size: 14px;
    box-shadow: 0 1px 0 0 #ebeef7;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;