.modalWrapper {
  :global {
    .ant-modal-body {
      height: 80vh;
      padding: 0;
    }
    .ant-drawer-inline {
      overflow: hidden;
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  height: 54px;
  padding: 0 54px 0 30px;
  line-height: 54px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.3);
  .name {
    flex: 1;
  }
  .tools {
    display: flex;
    align-items: center;
    & > * {
      margin-left: 8px;
    }
    .largeBtn {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 12px;
      color: #6b7a96;
      font-size: 14px;
      background-color: #f8f9fb;
      border-radius: 8px;
      cursor: pointer;

      :global {
        i {
          margin-right: 8px;
          color: #6b7a96;
          font-size: 16px;
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      cursor: pointer;
      &:hover {
        background-color: #f0f6ff;
      }
    }
  }
}

.container {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 0;

  .stat {
    width: 100%;
    height: 130px;
    padding: 0 24px;
  }
  .view {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 0;
    padding: 10px 24px 0;
    border-top: 1px solid rgba(216, 216, 216, 0.3);
    .viewInner {
      flex: 1;
      height: 0;
    }
  }
}

.settings {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;