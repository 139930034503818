#react-pi-grid {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow: hidden;

  #piGrid {
    width: 100%;
    height: 100%;
  }

  .react-pi-overlayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .vertical-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    height: calc(100% - 88px);
    overflow-x: hidden;
    overflow-y: scroll;
    opacity: 0.9;
  }

  .vertical-child {
    width: 15px;
  }

  .horizontal-container {
    position: relative;
    width: 100%;
    height: 14px;
    overflow-x: scroll;
    overflow-y: hidden;
    transform: translateY(-14px);
    opacity: 0.9;
  }

  .specialHorizontal {
    transform: translateY(-44px);
  }

  .horizontal-child {
    height: 1px;
  }
}
.status-btn {
  visibility: hidden;
}
.card-status-btn {
  visibility: visible;
}
.horizontal-container::-webkit-scrollbar {
  display: none;
}
.vertical-container::-webkit-scrollbar {
  display: none;
}
#react-pi-grid:hover {
  .horizontal-container::-webkit-scrollbar {
    display: block;
  }
  .vertical-container::-webkit-scrollbar {
    display: block;
  }
}
.horizontal-container:hover::-webkit-scrollbar {
  height: 14px;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;