.select-group {
  // for select
  & > :global(.ant-select) {
    &:hover {
      z-index: 1;
    }
    &-focused {
      z-index: 2;
    }
    &:not(:first-of-type) > .ant-select-selector {
      margin-block-start: -1px;
      border-block-start-width: 1px;
    }
    &:first-of-type > .ant-select-selector {
      border-radius: 0;
      border-start-end-radius: 6px;
      border-start-start-radius: 6px;
    }
    &:last-of-type > .ant-select-selector {
      border-radius: 0;
      border-end-start-radius: 6px;
      border-end-end-radius: 6px;
    }
  }

  // for InlineErrorFormItem
  > * {
    margin: 0 !important;
    &:hover {
      z-index: 1;
    }
    :global {
      .ant-select {
        &:hover {
          z-index: 2;
        }
        &-focused {
          z-index: 2;
        }
      }
      .ant-select.ant-select-in-form-item.ant-select-status-error {
        z-index: 1;
      }
    }
    &:not(:first-of-type) {
      margin-block-start: -1px !important;
      :global {
        .ant-select.ant-select-in-form-item > .ant-select-selector {
          border-block-start-width: 1px;
        }
      }
    }
    &:first-of-type {
      :global {
        .ant-select.ant-select-in-form-item > .ant-select-selector {
          border-radius: 0;
          border-start-end-radius: 6px;
          border-start-start-radius: 6px;
        }
      }
    }
    &:last-of-type {
      :global {
        .ant-select.ant-select-in-form-item > .ant-select-selector {
          border-radius: 0;
          border-end-start-radius: 6px;
          border-end-end-radius: 6px;
        }
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;