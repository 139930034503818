.scanAddModalBody {
  width: 100%;
  height: 100%;
  margin-top: 24px;
  padding: 16px 24px;
  overflow-y: auto;
}

.activeRecord {
  color: #ff7127;
}

.ellipsis {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.titleParent {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 12px 24px;
  background: #f6f8fc;
}

.titleParent > span {
  white-space: nowrap;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;