.tabs {
  height: 100%;
  :global {
    .ant-tabs-tabpane,
    .ant-tabs-content-holder,
    .ant-tabs-content {
      height: 100%;
    }
    .ant-tabs-nav-list {
      padding-left: 20px;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      // transform: translateX(20px);
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;