.ql-pm {
  &-container {
    border-radius: 8px;
    border: 1px solid #ebedf0;
  }

  &-editor {
    min-height: 360px;

    & > * {
      word-break: break-all;
    }

    .quill-prop-blot {
      &.selected {
        background-color: #316ef5;
        color: #fff;
        padding: 3px 0;
      }
    }
  }

  &-toolbar {
    border-bottom: 1px solid #ebedf0;
    padding: 8px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;