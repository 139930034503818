.popover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
      overflow: hidden;
      border-radius: 8px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

.container {
  width: 712px;
  border-radius: 8px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    border-bottom: 1px solid #e5e5e5;

    .batchDownLoad {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 12px;
      color: #96a1b5;
      font-size: 12px;
      background-color: #f8f9fb;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .main {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 16px;

    .attachment {
      position: relative;
      margin: 8px;

      .showChecked {
        position: absolute;
        top: 8px;
        left: 12px;
      }

      //   img
      .attachmentImg,
      .fileImg {
        width: 120px;
        height: 120px;
        background-position: center;
        background-size: cover;
        cursor: pointer;
      }

      .fileImg {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8f9fb;
      }

      .attachmentName {
        width: 120px;
        overflow: hidden;
        color: #767c88;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .mask {
        position: absolute;
        top: 0;
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 120px;
        padding: 8px 12px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &:hover {
        .checked {
          display: none;
        }

        .mask {
          display: flex;
        }
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;