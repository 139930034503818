.ql-pi-toolbar {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  padding-bottom: 0;

  border-bottom: 1px solid #ebedf0;

  & > * {
    display: inline-flex;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .tool-group {
    &-item {
      &:first-child:not(:last-child) {
        margin-left: 8px;
      }
      &:last-child:not(:first-child) {
        margin-right: 8px;
      }
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;