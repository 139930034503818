@border-color: #ebeef7;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: grab;
  user-select: none;

  &.list {
    width: 100%;
    height: 98px;
    border-top: 1px solid @border-color;
    &:first-of-type {
      border-top: 0;
    }
  }

  &.grid {
    display: inline-flex;
    width: 50%;
    height: 80px;
    border-right: 1px solid @border-color;
    border-bottom: 1px solid @border-color;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      border-top: 1px solid @border-color;
    }
    &:nth-of-type(2n) {
      border-right: 0;
    }
  }
  .title {
    color: #242d3f;
    font-weight: 400;
    font-size: 12px;
  }

  &.disabled::after {
    position: absolute; /* 定位到相对父元素 */
    top: 0;
    left: 0;
    width: 100%; /* 宽度与父元素相同 */
    height: 100%; /* 高度与父元素相同 */
    background: rgba(255, 255, 255, 0.7); /* 白色蒙层，0.5表示半透明 */
    cursor: not-allowed;
    content: ''; /* 用于创建伪元素 */
  }

  &:global(.ui-draggable-dragging) {
    background-color: rgba(107, 122, 150, 0.05);
    border: 0;
    border-radius: 4px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;