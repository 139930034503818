.messagesArea {
  position: relative;
  width: 100%;
  height: calc(100% - 260px);
  padding: 16px;
  overflow: auto;
  overflow-x: hidden;
  background-color: #f7f8fa;

  .mySelf {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-bottom: 24px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 50%;
      margin-right: 12px;
      color: #767c88;
      font-size: 12px;

      .contentBox {
        max-width: 100%;
        margin-top: 2px;
        padding: 6px 10px;
        word-break: break-all;
        background-color: white;
        border-radius: 12px 0 12px 12px;

        img {
          width: 100%;
          max-height: calc(50vh - 130px);
        }

        .at {
          color: #316ef5;
          cursor: pointer;
        }

        .self {
          color: white;
          background-color: #316ef5;
          cursor: pointer;
        }

        .fileWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 100%;
          padding: 7px 16px;
          font-size: 15px;
          background-color: #f5f7fc;
          border: 1px solid #dededf;
          border-radius: 6px;

          .unknowFileImg {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }

          .download {
            margin-left: 30px;
          }

          a {
            padding-left: 6px;
            font-weight: bold;
          }
        }
      }

      &:hover {
        + .more .dot {
          display: block;
        }
      }
    }

    .more {
      position: relative;
      flex: 1;

      .dot {
        position: absolute;
        right: 8px;
        bottom: 0;
        display: none;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
      }

      &:hover {
        .dot {
          display: block;
        }
      }

      :global {
        .ant-popover-inner-content {
          padding: 0 4px 0 4px;
        }
      }

      .action {
        height: 34px;
        padding: 0 16px;
        color: #374562;
        font-size: 12px;
        line-height: 34px;
        cursor: pointer;

        &:hover {
          background: #f6f8fc;
        }
      }
    }
  }

  .others {
    display: flex;
    justify-content: start;
    width: 100%;
    margin-bottom: 24px;

    .content {
      display: flex;
      flex-direction: column;
      max-width: 50%;
      margin-left: 12px;
      color: #767c88;
      font-size: 12px;

      .contentBox {
        max-width: 100%;
        margin-top: 2px;
        padding: 6px 10px;
        word-break: break-all;
        background-color: white;
        border-radius: 0 12px 12px 12px;

        img {
          width: 100%;
          max-height: calc(50vh - 130px);
        }

        .at {
          color: #316ef5;
          cursor: pointer;
        }

        .self {
          color: white;
          background-color: #316ef5;
          cursor: pointer;
        }

        .fileWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 100%;
          padding: 7px 16px;
          font-size: 15px;
          background-color: #f5f7fc;
          border: 1px solid #dededf;
          border-radius: 6px;

          .unknowFileImg {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }

          .download {
            margin-left: 30px;
          }

          a {
            padding-left: 6px;
            font-weight: bold;
          }
        }
      }

      &:hover {
        + .more .dot {
          display: block;
        }
      }
    }

    .more {
      position: relative;
      flex: 1;

      .dot {
        position: absolute;
        bottom: 0;
        left: 8px;
        display: none;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
      }

      &:hover {
        .dot {
          display: block;
        }
      }

      :global {
        .ant-popover-inner-content {
          padding: 0 4px 0 4px;
        }
      }

      .action {
        height: 34px;
        padding: 0 16px;
        color: #374562;
        font-size: 12px;
        line-height: 34px;
        cursor: pointer;

        &:hover {
          background: #f6f8fc;
        }
      }
    }
  }

  .time {
    padding: 2px 0;
    color: #b6b8b9;
    font-size: 12px;
    text-align: center;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f0f0f0;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;