.prop-body {
  //border-left: 1px solid #ebedf0;
  display: flex;
  flex-direction: column;
  // margin-bottom: 32px;
  font-size: 12px;
  i {
    font-size: 12px;
  }
  .prop-item {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 2px;
    color: #767c88;
    &-name {
      display: flex;
      align-items: center;
      width: 33.3%;
      height: 32px;
      margin-right: 2px;
      padding: 0 16px;
      background: #f8f9fb;
      i {
        font-size: 14px;
      }
      .prop-important {
        margin-left: 4px;
        color: #f0665e;
        font-size: 20px;
      }
    }
    &-value {
      display: flex;
      align-items: center;
      width: 66.6%;
      height: 32px;
      background: #f8f9fb;
      &-label {
        width: 100%;
        height: 32px;
        padding: 0 0 0 16px;
        line-height: 32px;
        &.prop-invalid {
          width: 50%;
          border: 1px solid #f0665e;
          border-radius: 8px;
        }
        .quote-count {
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: #316ef5;
          }
        }
        i {
          margin-left: 2px;
          padding: 4px 2px;
          color: #316ef5;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background: #f0f6ff;
          }
        }
      }
      .prop-err-msg {
        width: 50%;
        margin-left: 12px;
        color: #f0665e;
        font-size: 12px;
        i {
          margin-right: 4px;
        }
      }
      &-input {
        width: 100%;
        .edit-select {
          width: 100%;
          .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border-color: #316ef5;
            border-radius: 8px;
          }
          .ant-select-selector {
            border: 1px solid #316ef5;
            border-radius: 8px;
            //.ant-select-selection-overflow {
            //  height: 30px;
            //  overflow-y: auto;
            //}
            .ant-select-selection-overflow {
              //  height: 30px;
              flex-wrap: nowrap;
              overflow-x: auto;
            }
            .ant-select-selection-overflow::-webkit-scrollbar {
              height: 0;
            }
          }
        }
        .edit-input {
          width: 100%;
          border-radius: 8px;
          &.addon-before {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          &.addon-after {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:hover {
            border-color: #316ef5;
          }
          &:focus {
            border-color: #316ef5;
          }
          .ant-input-number-group-addon {
            max-width: 154px;
            overflow: hidden;
            color: #242d3f;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .ant-picker-input > input:hover {
          border-color: #316ef5;
          border-radius: 8px;
        }
        .ant-picker {
          width: 100%;
          border: 1px solid #316ef5;
          border-radius: 8px;
        }
        .rc-virtual-list-holder {
          overflow-y: unset !important;
        }
      }
    }
  }
  .prop-item:first-child {
    .prop-item-name {
      border-radius: 8px 0 0 0;
    }
    .prop-item-value {
      border-radius: 0 8px 0 0;
    }
  }
  .prop-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    color: #767c88;
    border: 2px solid #fafafc;
    border-radius: 8px;
    &.hasProp {
      border-radius: 0 0 8px 8px;
    }
    i {
      color: #b6b8b9;
    }
    &-add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 98px;
      cursor: pointer;
      i {
        margin-right: 4px;
      }
    }
    &-collapse {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 14px;
      cursor: pointer;
      i {
        margin-left: 4px;
      }
      &.visible {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
  .prop-empty {
    width: 100%;
    height: 40px;
    color: #242e41;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
  }
  .add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    color: #b7bac0;
    font-size: 14px;
    line-height: 40px;
    border-radius: 8px;
    cursor: pointer;
    i {
      margin-right: 6px;
      color: #dcdde0;
      font-size: 14px;
    }
    .iconProp_unfold {
      margin: 0 0 0 6px;
      transform: rotate(-180deg);
    }
    &:hover {
      background: #f7f7f9;
    }
  }
  .prop-collapse {
    display: flex;
    align-items: center;
  }
  .prop-add {
    display: flex;
    align-items: center;
    width: 124px;
    height: 32px;
    margin: 4px 16px;
    padding: 6px 12px;
    color: #b6b8b9;
    background: #f8f9fb;
    border-radius: 8px;
    cursor: pointer;
    i {
      margin-top: 1px;
      margin-right: 4px;
      font-size: 12px;
    }
    &:hover {
      color: #316ef5;
      background-color: #f4f7fd;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;