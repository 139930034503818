.wrapper {
  :global {
    .ant-modal-content {
      height: 90vh;
    }
    .ant-modal-body {
      height: calc(100% - 55px);
    }
  }
}

.fullScreen {
  :global {
    .ant-modal-content {
      height: 100vh;
    }
  }
}

.button {
  border-radius: 8px !important;
  &.darkBtn {
    color: #98a2b6 !important;
    background-color: rgba(248, 249, 251, 0.05);
    &:focus,
    &:hover {
      background-color: rgba(248, 249, 251, 0.05);
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;