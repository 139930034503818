.rowContent {
  padding-top: 0;
  :global {
    .ant-popover-inner {
      border-radius: 8px;
      box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.1);
    }
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  }

  .rowContentInner {
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 600px;
    padding: 16px 0 14px;
    .search {
      height: 32px;
      margin: 0 20px;
      background-color: #f8f9fb;
      border-radius: 8px;
      :global {
        .iconsearch {
          color: #b6b8b9;
          font-size: 16px;
        }
      }
    }
    .list {
      flex: 1;
      height: 0;
      margin-top: 8px;
      .listInner {
        padding: 0 20px;
      }
      .listItem {
        display: flex;
        align-items: center;
        height: 32px;
        margin-bottom: 8px;
        background-color: #fff;
        transition: all 0.3s;
        &:hover {
          background-color: #fafafa;
        }
        .drag {
          color: #374562;
          font-size: 20px;
          cursor: move;
        }
        .name {
          flex: 1;
          width: 0;
          margin: 0 8px;
          color: #374562;
          font-size: 14px;
          cursor: move;
        }
        :global {
          .ant-switch-checked {
            background-color: #316ef5;
          }
        }
      }
    }
  }
}

.darkBlock {
  &:hover {
    background-color: rgba(248, 249, 251, 0.05);
  }
}

.button {
  &:focus,
  &:hover {
    color: #98a2b6;
  }
}

.mainRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px;
  font-weight: 700;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;