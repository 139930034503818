.view-setting-block {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 12px;
  color: #98a2b6;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  i {
    font-size: 12px;
  }
  .icon-right {
    margin-left: 12px;
  }
  .iconxiala {
    margin-top: -2px;
  }

  &:hover {
    background-color: #f7f9fb;
  }
}

.view-setting-block-dark {
  &:hover {
    background-color: rgba(248, 249, 251, 0.05);
  }
  .ant-switch:not(.ant-switch-checked) {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .ant-switch {
    box-shadow: none !important;
  }
}

.saveGroupByConfig {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 24px;
  font-size: 12px;
  background: #f8f9fb;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;