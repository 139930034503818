.wrapper {
  display: flex;
  flex-direction: column;
  :global {
    .ant-radio-wrapper + .ant-radio-wrapper,
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 0;
    }
    label.ant-radio-wrapper,
    label.ant-checkbox-wrapper {
      display: flex;
      padding: 6px 12px;
      border-radius: 8px;
      transition: all 0.2s ease;

      &:hover {
        background-color: #f8f9fb;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;