.screen {
  #SplitPane {
    .sidebar {
      display: none;
    }
    .resizer {
      display: none;
    }
    .breadcrumb {
      z-index: 0 !important;
    }
  }
  #HomeLayout .homelayout-main {
    min-width: auto;
  }
}
