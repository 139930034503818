.indexCard {
  height: 40px;
  border-radius: 8px;
  background-color: #f8f9fb;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 10px;
  .name {
    font-size: 14px;
    color: #242d3f;
    font-weight: 400;
    flex: 1;
  }
  .handBtns {
    display: none;
    align-items: center;
  }
  &:hover .handBtns {
    display: flex;
  }
}

.subitemEditPopver {
  font-size: 14px;
  color: #242d3f;
  width: 288px;

  .title {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .subTitle {
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .value {
    margin-bottom: 16px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;