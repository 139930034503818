.link-pi-modal {
  .ant-modal-content {
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.1);
    .ant-modal-header {
      padding: 14px 20px;
      border-color: #e5e7eb;
      border-radius: 8px 8px 0 0;
      .ant-modal-title {
        .link-modal-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          &-main {
            color: #242e41;
            font-weight: 500;
            font-size: 18px;
          }
          &-sub {
            color: #858fa5;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
    .ant-modal-body {
      height: calc(100% - 60px);
      padding: 24px 42px 0 24px;
      color: #242e41;
      font-weight: 400;
      font-size: 14px;
      .ant-form-item-label > label {
        color: #767c88;
      }
      //.ant-select-selection-placeholder {
      //  color: #C9CCCE;
      //}
      .ant-form-item {
        .tag-input {
          width: 78px;
          height: 32px;
          margin-right: 8px;
          vertical-align: top;
        }
        .ant-tag {
          height: 32px;
          margin: 0 12px 6px 0;
          padding: 0 12px;
          line-height: 32px;
          background-color: #f8f9fb;
          border: none;
          border-radius: 6px;
        }
        .site-tag-plus {
          color: #767c88;
          background-color: #f7f9fb;
          border: none;
          cursor: pointer;
          //border-style: dashed;
          &:hover {
            color: #316ef5;
          }
        }
        .edit-tag {
          user-select: none;
        }
      }
    }
    .ant-modal-footer {
      padding: 22px 32px;
      padding-top: 12px;
      padding-right: 42px;
      border: none;
      .ant-btn {
        margin-left: 24px;
      }
    }
    .ant-modal-close {
      color: #d8d8d8;
      &:hover {
        color: #858fa5;
      }
    }
  }
  &.no-header {
    .ant-modal-header {
      display: none;
    }
  }
}
.user-option-label {
  display: flex;
  align-items: center;
  span {
    margin-right: 4px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;