.view-add-modal {
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-modal-title {
    color: #242d3f;
    font-size: 18px;
  }
  .ant-modal-footer {
    border: none;
  }
}
.view-add-modal-form {
  .set-title {
    color: #242d3f;
    font-weight: 500;
    font-size: 18px;
  }
  .view-set-btns {
    display: flex;
    flex-direction: row-reverse;
    button {
      margin-left: 24px;
    }
  }
  .title-space-between {
    margin-top: 48px;
  }
  .form-label {
    margin-bottom: 12px;
    color: #b6b8b9;
  }
  .line-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    .line-add-btn {
      color: #6b7a96;
      font-size: 12px;
      background-color: #f7f9fb;
      border: none;
      &-primary {
        color: #316ef5;
        background-color: #f4f5f6;
        border: none;
      }
      &:hover {
        color: #6b7a96;
        background-color: #eaeff4;
      }
    }
    .ant-checkbox-wrapper {
      color: #242e41;
    }
    &-reverse {
      flex-direction: row-reverse;
    }
  }

  .view-terms-set {
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 32px;
  }
  .vertical-items {
    width: 100%;
    margin-bottom: 12px;
    padding: 12px;
    background-color: #f4f5f6;
    border-radius: 8px;
  }
  .form-list-item {
    width: 100%;
    margin-bottom: 12px;
    .list-item {
      display: inline-block;
      width: calc(100% - 40px);
      margin: 0 8px 0 0;
    }
    .group-item {
      display: inline-block;
      width: calc(50% - 20px);
      margin: 0 8px 0 0;
    }
    .group-key {
      display: inline-block;
      width: calc(35% - 20px);
      margin: 0 8px 0 0;
    }
    .group-value {
      display: inline-block;
      width: calc(65% - 20px);
      margin: 0 8px 0 0;
    }
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
    .cancel-btn {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-top: 4px;
      background-color: #f7f9fb;
      border-radius: 100%;
      &:hover {
        background-color: #f0f6ff;
        i {
          color: #316ef5;
        }
      }
      i {
        margin-left: 6px;
        color: #6b7a96;
        font-size: 12px;
      }
    }
  }
  .view-type-select {
    display: flex;
    flex-wrap: wrap;
    .view-type {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      width: 100px;
      height: 100px;
      margin: 0 10px 10px 0;
      padding: 16px 0 12px 0;
      color: #767c88;
      border: 1px solid #ebedf0;
      border-radius: 6px;
    }
    .active {
      color: #316ef5;
      border-color: #a9c3ff;
    }
  }
  .form-scope {
    display: flex;
    .form-scope-label {
      width: calc(35% - 20px);
      height: 32px;
      margin-right: 8px;
      padding: 0 11px;
      line-height: 32px;
      border: 1px solid #ebedf0;
      border-radius: 4px;
    }
    .form-scope-value {
      width: calc(65% - 20px + 24px + 6px);
    }
    .form-item-parent-select {
      display: flex;
      margin-bottom: 8px;
    }
    .cancel-btn {
      margin-left: 8px;
    }
  }

  .ant-form-item-label > label {
    color: #b6b8b9 !important;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;