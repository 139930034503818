.container {
  display: flex;
  height: 100%;
  min-height: 300px;
}
.main {
  flex: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  .head {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #ffffff;
    box-shadow: 0px 1px 0px 0px #ebedf0;
    padding: 0 16px;
    position: relative;
  }
  .preview {
    flex: 1;
    background-color: #f5f7fa;
    padding: 24px;
    .previewWrapper {
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 4px 8px 0px rgba(77, 99, 146, 0.1);
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;