:global {
  .dashboard-modal .ant-modal-body {
    padding: 0;
  }
  .dashboard-drawer .ant-drawer-body {
    padding: 0;
  }
}
.container {
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow-y: auto;
}

.step1 {
  display: flex;
  .componentTypeCard {
    position: relative;
    width: 180px;
    height: 180px;
    margin-right: 17px;
    padding-top: 103px;
    padding-left: 18px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.1);
    }
    .componentTypeCardBg {
      position: absolute;
      top: 0;
      left: -1px;
      width: 100%;
      border-radius: 8px 8px 0 0;
    }
    .componentTypeCardIcon {
      position: absolute;
      top: 43px;
      left: 18px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
      :global {
        .iconfont {
          color: #fff;
          font-size: 20px;
        }
      }
    }

    .componentTypeCardName {
      color: #242e41;
      font-weight: 600;
      font-size: 14px;
    }
    .componentTypeCardDesc {
      margin-top: 7px;
      color: #b6b8b9;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.step2 {
  .typeInfo {
    position: relative;
    height: 40px;
    margin-bottom: 40px;
    padding-left: 52px;
    .typeInfoIcon {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
      :global {
        .iconfont {
          color: #fff;
          font-size: 20px;
        }
      }
    }
    .typeInfoName {
      color: #242e41;
      font-size: 16px;
      line-height: 22px;
    }
    .typeInfoDesc {
      color: #b7bac0;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .group {
    margin-bottom: 30px;
    .groupHead {
      position: relative;
      height: 24px;
      margin-bottom: 12px;
      padding-left: 12px;
      color: #242e41;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      &::after {
        position: absolute;
        top: 6px;
        left: 0;
        width: 4px;
        height: 12px;
        background-color: #316ef5;
        border-radius: 2px;
        content: '';
      }
    }
    .groupHeadButton {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 0 13px;
      color: #316ef5;
      font-weight: 400;
      font-size: 14px;
      background-color: rgba(49, 110, 245, 0.1);
      border-radius: 8px;
      cursor: pointer;
      :global {
        .iconfont {
          margin-right: 5px;
          color: #316ef5;
          font-size: 14px;
        }
      }
    }
  }
  .relationCard {
    position: relative;
    height: 66px;
    padding: 13px 13px 13px 48px;
    background-color: #f8f9fb;
    border-radius: 8px;
    cursor: pointer;

    .relationCardIcon {
      position: absolute;
      top: 13px;
      left: 12px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
    }
    .relationCardName {
      width: 100%;
      color: #242e41;
      font-size: 15px;
      line-height: 22px;
    }
    .relationCardPath {
      margin-top: 2px;
      color: #b6b8b9;
      font-size: 12px;
    }
  }
}

.step3 {
  display: flex;
  flex-direction: column;
  .viewList {
    flex: 1;
    height: 0;
    margin-top: 8px;
    .viewItem {
      position: relative;
      height: 66px;
      padding: 13px 13px 13px 48px;
      cursor: pointer;

      &:hover {
        background-color: #f8f9fb;
      }

      .viewItemIcon {
        position: absolute;
        top: 13px;
        left: 12px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 12px;
      }
      .viewItemName {
        width: 100%;
        color: #242e41;
        font-size: 15px;
        line-height: 22px;
      }
      .viewItemPath {
        margin-top: 2px;
        color: #b6b8b9;
        font-size: 12px;
      }
    }
    .viewListPlaceholder {
      width: 100%;
      color: #b9bbbc;
      font-size: 16px;
      text-align: center;
    }
  }
}

.custom-upload {
  :global {
    .ant-upload.ant-upload-select-picture-card {
      width: 40px;
      height: 40px;
    }
    .ant-upload-picture-card-wrapper {
      width: auto;
    }
  }
}
.custom-sign-upload {
  :global {
    .ant-upload.ant-upload-select-picture-card {
      width: 60px;
      height: 60px;
    }
    .ant-upload-picture-card-wrapper {
      width: auto;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;