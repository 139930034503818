@import './assets/styles/reset.less';

[role='button'],
button {
  cursor: pointer;
}

// 兼容IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

html,
body,
#root {
  // height: 100%;
  height: 100vh;
  background-color: #fff;
}

html {
  overflow: hidden;
  overscroll-behavior: none;
}

//自动移滚动条样式
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(var(--border));
  border-radius: 10px;
}

.text-omit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-btn-circle {
  border-radius: 50%;
}
.ant-modal-footer button + button:not(.ant-btn-compact-item) {
  margin-left: 12px;
}
.ant-checkbox-wrapper {
  color: #242e41;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5 !important;
}
.ant-avatar-string {
  font-size: 13px;
}
.setting-title {
  margin: 4px 0 20px;
  padding-left: 6px;
  color: #242e41;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  border-left: 4px solid #316ef5;
}
.iconquxiaoguanzhu1,
.iconquxiaoguanzhu2 {
  color: #ffd031;
}
.ps__thumb-y {
  min-height: 18px;
}
.link-pi-btn {
  display: flex;
  align-items: center;
  height: 24px;
  margin-right: 8px;
  padding: 4px 8px;
  color: #97a1b5;
  white-space: nowrap;
  background: #f8f9fb;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #316ef5;
    background-color: #f4f7fd;
  }
  &.hide {
    visibility: hidden;
  }
  &.btn-lg {
    height: 32px;
  }
  &.padding-lg {
    padding: 6px 12px;
  }
  .icon-left-4 {
    margin-right: 4px;
  }
  .icon-left-8 {
    margin-right: 8px;
  }
}
.link-pi-btn-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #f8f9fb;
  border-radius: 100%;
  cursor: pointer;
  &.btn-small {
    width: 24px;
    height: 24px;
  }
  i {
    color: #5f6e8a;
    font-size: 12px;
  }
  &:hover {
    background-color: #f4f7fd;
  }
}

.link-pi-des-btn {
  height: 20px;
  margin-left: 8px;
  padding: 0 6px;
  color: #316ef5;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  background-color: #eff4ff;
  border-radius: 10px;
}
.link-pi-btn-text {
  padding: 0 6px;
  color: #316ef5;
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  i {
    margin-right: 5px;
    color: #316ef5;
    font-size: 12px;
  }
}
.default-words {
  margin-top: 8px;
  color: #b6b8b9;
  line-height: 20px;
}

/**
* antd 4.24 break
*/
.ant-popover-content .ant-popover-inner-content {
  width: 100%;
}

// fix perfect-scroll导致无法通过浏览器ctrl+f搜索滚动的问题
// 因为容器被设置了overflow:hidden
.ps {
  overflow: auto !important;
  overscroll-behavior: contain;
}
.ps__rail-y {
  z-index: 3;
}
.ps:hover > .ps__rail-y {
  opacity: 0;
}
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6 !important;
}
.ps::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.ant-tree-list-scrollbar-thumb {
  background: hsl(var(--border)) !important;
}
.ant-form-item-align-center {
  .ant-row {
    align-items: center;
  }
}

// popconfirm 按钮 loading 时会变形
.ant-popover-buttons {
  white-space: nowrap;
}

// .grid-stack-item-content:hover + .ui-resizable-handle {
// display: block !important;
// }

.pi-form-item-compact {
  margin-bottom: 0 !important;
}

.ant-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/*
 * 隐藏相邻的分隔线
 */
.dividerListWrapper {
  .ant-divider.ant-divider-vertical + .ant-divider.ant-divider-vertical,
  .ant-divider.ant-divider-vertical:first-of-type {
    display: none;
  }
  .link-pi-btn {
    margin-right: unset;
  }
}

.hover-scroll-bar {
  overflow: hidden;
  scrollbar-gutter: stable;
}

.hover-scroll-bar:hover {
  overflow-y: scroll;
}

.full-w {
  width: 100%;
}

@import './assets/styles/screen.less';

.patched-bg-white {
  .ant-transfer-list-body {
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.ant-alert {
  align-items: baseline;
}

.ant-alert-warning {
  background-color: rgba(255, 128, 0, 0.1);
  border-color: rgba(255, 128, 0, 0.2);
}

.ant-alert-icon {
  transform: translateY(2px);
}

.ant-alert-warning .ant-alert-icon {
  color: #ff8000;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;