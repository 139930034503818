.statusMenuWrap {
  position: absolute;
  pointer-events: none;

  :global {
    .status-btn {
      color: transparent;
      background-color: transparent;

      i {
        color: transparent !important;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;