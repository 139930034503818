#viewCharts {
  height: 600px;
  //width: calc(100% - 24px);
}
.charts-export-button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 60px;
  margin: 0 32px 0 42px;
  button {
    width: 80px;
  }
  .handles-set {
    width: 98px;
    height: 32px;
    margin-left: 12px;
    color: #9ca6b9;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    background-color: #f7f9fb;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
    &.disabled {
      cursor: not-allowed;
    }
    i {
      margin-right: 4px;
      font-size: 12px;
    }
    &:hover {
      background-color: #f4f7fd;
    }
  }
}
#viewCharts + .view-charts-table {
  margin: 12px 0;
}
.view-charts-table {
  display: flex;
  flex-direction: column;
  // width: calc(100% - 104px);
  // margin: 12px 40px;
  .view-table {
    border: 1px solid #f6f6f6;
    border-radius: 8px;
    .ant-table-tbody > tr > td {
      border: none;
    }
    .ant-table-thead > tr > th {
      color: #b6b8b9;
      border: none;
    }
    .view-table-row {
      color: #242e41;
    }
    .view-row-head {
      color: #b6b8b9;
    }
  }
}

.view-dark-charts-table {
  display: flex;
  flex-direction: column;
  // width: calc(100% - 104px);
  // margin: 12px 40px;
  .ant-table {
    background-color: transparent;
  }
  .view-dark-table {
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    .ant-table-tbody > tr > td {
      border: none;
    }
    .ant-table-thead > tr > th {
      color: rgba(17, 197, 167, 1);
      background-color: rgba(17, 197, 167, 0.2); //rgba(25, 67, 105, .2);
      border: none;
      box-shadow: none !important;

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        color: rgba(214, 224, 255, 0.7) !important;
      }
      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: rgba(255, 255, 255, 1) !important;
      }
    }
    .view-table-row {
      color: rgba(255, 255, 255, 1);
      background-color: rgba(255, 255, 255, 0.05);

      .ant-table-cell-row-hover {
        background-color: rgba(214, 224, 255, 0.03) !important;
      }

      .ant-table-column-sort {
        background-color: rgba(214, 224, 255, 0.03) !important;
      }
    }
    .view-row-head {
      color: rgba(214, 224, 255, 0.3);
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;