#QuoteNodes {
  height: 100%;
  .quote-table {
    width: 958px;
    //height: 572px;
    &-header {
      position: sticky;
      top: 0;
      z-index: 1001;
      display: flex;
      align-items: center;
      height: 40px;
      margin-bottom: 8px;
      color: #767c88;
      background: #f9fafc;
      border-radius: 8px;
      &-cell {
        padding: 0 12px;
        &.sticky {
          position: sticky;
          left: 0;
          z-index: 1002;
          display: flex;
          align-items: center;
          height: 40px;
          background: #f9fafc;
        }
        &.shadow {
          box-shadow: inset -10px 0 8px -8px #00000026;
        }
      }
    }
    &-body {
      height: 524px;
      //overflow-y: auto;
      color: #242e41;
      &-column {
        display: flex;
        align-items: center;
        height: 52px;
        margin-bottom: 4px;
        background: #f9fafc;
        border-radius: 8px;
        &-cell {
          padding: 0 12px;
          background: #f9fafc;
          i {
            margin-right: 4px;
            font-size: 18px;
          }
          &.status {
            display: flex;
            align-items: center;
          }
          &.sticky {
            position: sticky;
            left: 0;
            z-index: 1000;
            display: flex;
            align-items: center;
            height: 52px;
            background: #f9fafc;
          }
          &.shadow {
            box-shadow: inset -10px 0 8px -8px #00000026;
          }
        }
      }
    }
  }
}

.add-quote-node-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 32px;
  color: #6b7a96;
  font-size: 15px;
  background-color: #f4f7fd;
  border-radius: 8px;
  cursor: pointer;
}

.quote-nodes-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal {
    top: 0 !important;
    width: 80% !important;
    min-width: 1024px;
    max-width: 1800px;
    height: 90%;
    padding-bottom: 0;
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
  }
  .ant-modal-body {
    width: 100%;
    height: calc(100% - 62px);
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;