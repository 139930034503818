.split-number {
  color: #242e41;
  &-tab {
    display: flex;
    margin-bottom: 16px;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 16px;
      font-weight: 600;
      background-color: #f7f9fb;
      cursor: pointer;
      &.active {
        color: #316ef5;
        background: #ebf0fd;
      }

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }
  &-label {
    display: flex;
    align-items: center;
    height: 20px;
    &-des {
      margin-left: 8px;
      color: #a7abb2;
    }
  }
  &-input {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    .iconButton_add {
      width: 32px;
      height: 32px;
      color: #b5bdcb;
      font-size: 12px;
      line-height: 32px;
      text-align: center;
      border: 1px dashed #cad0d8;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        color: #316ef5;
        border-color: #316ef5;
      }
    }
    .ant-input-number-group-addon {
      padding: 0 4px;
      background: #fff;
      i {
        padding: 0 4px;
        cursor: pointer;
        &:hover {
          color: #f0665e;
        }
      }
    }
  }

  &-total {
    height: 20px;
    margin-bottom: 12px;
    color: #b7bac0;
    font-weight: 400;
    font-size: 14px;

    .ant-checkbox-wrapper {
      color: #b7bac0;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.split-number-modal {
  .ant-modal-body {
    height: 540px;
    padding: 24px 32px 0 24px !important;
    overflow-y: auto;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;