.verticalTable {
  border-radius: 0 !important;
  :global {
    .ant-table-placeholder {
      display: none;
    }
    table,
    .ant-table-container,
    .ant-table-thead,
    .ant-table-thead > tr {
      border-radius: 0 !important;
    }
    .ant-table-thead > tr > th {
      white-space: nowrap;
      border-radius: 0 !important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: unset;
    }

    .ant-table-tbody > tr.ant-table-row > td:first-of-type,
    .ant-table-thead > tr > th:first-of-type {
      background-color: #fafafa;
      font-weight: bold;
    }
    .ant-table-summary > tr > td:first-of-type {
      border-right: 1px solid #fff !important;
    }

    .headerClass {
      min-width: 150px !important;
    }
    .cellClass {
      background-color: #fafafa;
      border-right: 1px solid #fafafa !important;
      min-width: 260px;
    }
  }
}

.cell-number-input {
  :global {
    .ant-input-number-input-wrap {
      min-width: 45px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;