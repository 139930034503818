#TempPropModal {
  .prop-list {
    //overflow-y: auto;
    position: absolute;
    top: 0;
    left: -128px;
    width: 128px;
    height: 743px;

    &-item {
      width: 120px;
      height: 32px;
      margin-bottom: 8px;
      padding: 0 12px;
      color: #767c88;
      font-size: 12px;
      line-height: 32px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      i {
        padding: 2px 4px 0 0;
        font-size: 12px;
      }

      &.active,
      &:hover {
        color: #316ef5;
        background: #f0f6ff;
      }
    }

    &-add {
      width: 120px;
      height: 32px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      background: #316ef5;
      border-radius: 8px;
      box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        background: #5c95ff;
      }
    }
  }

  .cascade-nodes-tree {
    margin-bottom: 24px;
    padding: 8px;
    background: #f5f5f5;

    .ant-tree-switcher {
      background: none;
    }
  }

  .cascade-quote-node {
    width: 100%;
    margin-bottom: 24px;
    padding: 16px;
    color: #767c88;
    line-height: 22px;
    background: #f8f9fb;
    border-radius: 8px;
  }

  .prop-item {
    margin-bottom: 24px;

    .ant-checkbox + span {
      user-select: none;
    }

    &.no-padding {
      margin-bottom: 10px;
    }

    &-space {
      height: 16px;

      &.small {
        height: 8px;
      }
    }

    .ant-checkbox-wrapper {
      color: #767c88;
    }

    &.horizontal {
      display: flex;
      align-items: center;

      .prop-item-label {
        margin-right: 8px;
        margin-bottom: 0;
      }
    }

    &-label {
      margin-bottom: 8px;
      color: #767c88;
      line-height: 20px;
    }

    &-input {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &.bordered {
        padding: 7px 11px;
        border: 1px solid #ebedf0;
        border-radius: 8px;
      }

      &-des {
        width: 100%;
        color: #bfc6d2;
      }

      &-btn {
        width: 118px;
        height: 32px;
        margin-right: 8px;
        color: #242e41;
        line-height: 32px;
        text-align: center;
        background: #f8f9fb;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;

        &:hover {
          color: #316ef5;
          background: #f0f6ff;
        }
        &.active {
          color: #316ef5 !important;
          background: #f0f6ff !important;
        }
      }

      .site-tag-plus {
        color: #316ef5;
        background: #f7f9fb;
        cursor: pointer;
      }

      .ant-tag {
        margin-bottom: 8px;
        padding: 0 16px;
        font-size: 14px;
        line-height: 32px;
        border: none;
        border-radius: 8px;
      }

      .tag-input {
        width: 109px;
        height: 32px;
        margin-right: 8px;
      }

      .ant-select,
      .ant-input-number {
        width: 100%;
      }

      .ant-select-selector,
      .ant-input-number,
      input {
        border-color: #ebedf0;
        border-radius: 8px;
      }

      &.inline-2 {
        input,
        .ant-select {
          width: calc(50% - 8px);
        }

        input:first-child,
        .ant-select:first-child {
          margin-right: 16px;
        }
      }

      &-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        margin: 0 14px 12px 0;
        color: #242e41;
        background: #f8f9fb;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        i {
          color: #767c88;
          font-size: 20px;
        }

        &:hover,
        &.active {
          color: #316ef5;
          background: #f0f6ff;

          i {
            color: #316ef5;
          }
        }
      }
    }
  }

  .prop-divider {
    width: 100%;
    height: 1px;
    margin: 18px 0;
    border-bottom: 1px dashed #ebedf0;
  }

  .prop-condition {
    width: 100%;
    padding: 20px 32px 18px 20px;
    background: #f8f9fb;
    border-radius: 8px;

    &-check {
      margin-bottom: 8px;
      margin-left: 76px;
      line-height: 22px;

      .ant-checkbox-wrapper {
        margin-right: 40px;
        color: #767c88;
      }
    }

    &-item {
      display: flex;
      margin-bottom: 8px;
      //align-items: center;
      line-height: 32px;

      &-label {
        width: 56px;
        margin-right: 20px;
        color: #767c88;
      }

      &-input {
        width: calc(100% - 76px);

        &.select {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          height: 32px;
          padding: 0 11px;
          overflow-x: auto;
          overflow-y: hidden;
          background: #fff;
          border: 1px solid #ebedf0;
          border-radius: 8px;
          cursor: pointer;
        }

        &-tag {
          flex: none;
          align-self: center;
          max-width: 100%;
          height: 24px;
          margin-right: 4px;
          padding: 0 12px;
          font-size: 12px;
          line-height: 24px;
          background: #f8f9fb;
          border-radius: 8px;

          i {
            padding: 0 4px;
            color: #6b7a96;
            font-size: 12px;
          }
        }

        .ant-select {
          width: 100%;

          .ant-select-selector {
            border-color: #ebedf0;
            border-radius: 8px;
          }
        }

        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          .ant-select {
            width: calc(50% - 70px);
          }

          &-symbol {
            width: 100px;

            .ant-select {
              width: 100px;
            }
          }

          &-value {
            width: calc(50% - 70px);

            &-item:not(:first-child) {
              margin-top: 8px;
            }

            &-item {
              display: flex;
              align-items: center;

              .ant-select {
                width: calc(100% - 60px);
              }

              i {
                margin-left: 12px;
                cursor: pointer;
              }
            }
          }

          .icona-zujian67 {
            cursor: pointer;

            &:hover {
              color: #f0665e;
            }
          }
        }

        &-add {
          width: 100%;
          color: #6b7a96;
          text-align: center;
          border: 1px dashed #dddee0;
          border-radius: 8px;
          cursor: pointer;

          i {
            margin-right: 4px;
            font-size: 12px;
          }
        }
      }

      &-btn {
        width: 118px;
        height: 32px;
        margin-right: 8px;
        color: #767c88;
        line-height: 32px;
        text-align: center;
        background: #fff;
        border: 1px solid #ebedf0;
        border-radius: 8px;
        cursor: pointer;

        &:hover,
        &.active {
          color: #316ef5;
          background: #f0f6ff;
          border: 1px solid #316ef5;
        }
      }
    }
  }

  .form-item {
    margin-bottom: 24px;
    //align-items: center;
    //height: 32px;
    line-height: 32px;

    .ant-input-number,
    .ant-picker {
      width: calc(100% - 22px);
    }

    &.numerical {
      .ant-input-number,
      .ant-picker {
        width: calc(100% - 60px);
      }
    }

    &-symbol {
      padding: 0 11px;
      color: #242e41;
      text-align: center;
      background: #ebedf0;
    }

    &-addon {
      display: flex;
    }

    .form-label {
      margin-bottom: 8px;
      color: #767c88;
      line-height: 20px;
    }

    .form-input {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 4px 11px;
      line-height: 24px;
      border: 1px solid #ebedf0;
      border-radius: 8px;

      &-des {
        color: #bfbfbf;
      }

      &.default {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        line-height: 32px;

        i {
          margin-right: 8px;
          color: #767c88;
          font-size: 14px;
          cursor: pointer;
        }
      }

      &.quote {
        padding: 0 0 0 11px;
        cursor: pointer;
      }
    }

    .form-inline {
      display: flex;
      align-items: center;
      width: 100%;

      &-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(50% - 12px);
        line-height: 32px;
        border: 1px solid #ebedf0;
        border-radius: 8px;

        i {
          margin-right: 8px;
          color: #767c88;
          font-size: 14px;
          cursor: pointer;
        }

        &.quote {
          padding-left: 11px;
          cursor: pointer;
        }
      }

      &-divider {
        width: 24px;
        text-align: center;
      }
    }
  }
}

.temp-prop-modal {
  .modal-title {
    display: flex;
    align-items: center;
    color: #242d3f;

    &-main {
      font-weight: 600;
    }

    &-sub {
      width: calc(100% - 90px);
    }
  }

  .ant-modal-body {
    max-height: calc(90vh - 60px);
    overflow-y: auto;
  }
}

.meridiem-picker {
  .ant-picker-panel {
    display: flex;
    flex-direction: row;

    .ant-picker-footer {
      width: 112px;
      min-width: 112px;

      &-extra {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 52px);
        margin-top: 40px;
        border-top: 1px solid #ebedf0;
      }
    }
  }

  &-extra {
    &-item {
      width: 96px;
      height: 40px;
      padding: 0 12px;
      line-height: 40px;
      border-radius: 8px;
      cursor: pointer;

      &.active,
      &:hover {
        background: #f0f6ff;
      }
    }
  }
}

.enum-columns {
  width: 545px;
  line-height: 40px;

  &-list {
    height: 388px;
    overflow-y: auto;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 8px;
      padding: 0 8px;
      border-radius: 8px;
      cursor: pointer;

      i {
        margin-right: 4px;
      }

      .iconselected {
        color: #316ef5;
      }

      .icondrag {
        &:hover {
          color: #316ef5;
        }
      }

      &:hover {
        background: #f0f6ff;
      }
    }
  }

  &-check {
    margin-left: 16px;
  }
}

.enum-column-pop {
  .ant-popover-inner-content {
    padding: 6px 0;
  }
}

.data-type-parent {
  display: flex;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;