.delete-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  background-color: #f8f9fb;
  border-radius: 50%;

  &-icon {
    color: #77849e;
    font-size: 10px;
  }
}

.form-item-node {
  display: inline-flex;
  align-items: center;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 32px;
  padding: 0 12px;
  color: #6b7a96;
  font-size: 14px;
  background-color: #f8f9fb;
  border-radius: 8px;
  cursor: pointer;
}

.form-item-price {
  display: flex;
  align-items: center;

  &-item {
    flex: 1;

    .price-item {
      width: 100%;
    }
  }

  .form-item-divider {
    margin: 0 8px;
  }
}

.upload-img {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.custom-upload {
  &-add {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #6b7a96;
    background-color: #f8f9fb;

    .upload-img;
  }
}

.upload-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.custom-upload-wrapper {
  display: flex;

  :global {
    .ant-upload.ant-upload-select-picture-card {
      .upload-img;
    }

    .ant-upload-list-picture-card-container {
      .upload-img;
    }

    .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 0;
    }
  }
}

.mode-container {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  margin-bottom: 24px;
  overflow: hidden;
  background-color: #f7f9fb;
  border-radius: 4px;
  cursor: pointer;

  .mode-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 22px;
    color: #242e41;
    font-size: 14px;

    &.active {
      color: #316ef5;
      font-weight: bold;
      background-color: #eaf0fe;
    }
  }
}

.publish-wrapper {
  width: 100%;
  max-height: 620px;
  padding-right: 15px;
  overflow-y: auto;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;