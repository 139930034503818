@import './assets/styles/variable.less';

.font-normal (@size: 12px, @color: #333) {
  color: @color;
  font-weight: normal;
  font-size: @size;
}

.text-ellipsis(@width: auto) {
  max-width: @width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.font-bold (@size: 12px, @color: #333) {
  color: @color;
  font-weight: bold;
  font-size: @size;
}
