.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: #fff;
  border: 1px solid rgba(235, 237, 240, 1);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;

  .title {
    margin-top: 16px;
  }
  &.active {
    border: 1px solid rgba(49, 110, 245, 1);
    .title {
      color: rgba(49, 110, 245, 1);
    }
  }
  :global {
    .ant-typography.ant-typography-secondary {
      font-size: 12px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;