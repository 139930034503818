html body,
body div,
body header,
body footer,
body section,
body aside,
body article,
body ul,
body dl,
body dt,
body dd,
body li,
body a,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body i,
body b,
body textarea,
body button,
body input,
body select {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  text-decoration: none;
  list-style: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body:focus,
body div:focus,
body header:focus,
body footer:focus,
body section:focus,
body aside:focus,
body article:focus,
body ul:focus,
body dl:focus,
body dt:focus,
body dd:focus,
body li:focus,
body a:focus,
body p:focus,
body h1:focus,
body h2:focus,
body h3:focus,
body h4:focus,
body h5:focus,
body h6:focus,
body i:focus,
body b:focus,
body textarea:focus,
body button:focus,
body input:focus,
body select:focus {
  outline: none;
}
html body,
body header,
body footer,
body section,
body aside,
body article,
body ul,
body dl,
body dt,
body dd,
body li,
body a,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body i,
body b,
body em,
body textarea,
body button,
body input,
body select {
  border: none;
}
body *,
body *::before,
body *::after {
  box-sizing: inherit;
  border-width: 0;
  border-style: solid;
}
html:first-child {
  box-sizing: border-box;
}
body img {
  -webkit-touch-callout: none;
}
body a {
  -webkit-touch-callout: none;
}
body a,
body a:active,
body a:hover {
  text-decoration: none;
}
body input[type='button'],
body input[type='submit'],
body input[type='search'],
body input[type='reset'] {
  -webkit-appearance: none;
}
body input,
body select,
body textarea {
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}
body canvas {
  display: block;
}
body textarea {
  -webkit-appearance: none;
}
body a,
body button,
body input,
body textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body img,
body a {
  -webkit-touch-callout: none;
}
