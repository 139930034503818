.vertical {
  padding: 8px;
  :global {
    .condition-filter-item-container {
      gap: 8px;
      display: flex;
      flex-direction: column;
    }

    .condition-filter-item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      background: #f8f9fb;
      border-radius: 8px;
      position: relative;
      padding: 24px 16px;
      padding-bottom: 14px;
      overflow: hidden;

      & > div {
        width: 100%;
      }
    }

    .condition-filter-item-btn {
      width: 18px !important;
      height: 18px !important;
      position: absolute;
      right: 0;
      top: -4px;
      border-radius: 0;
      border-bottom-left-radius: 8px;
      background-color: #ebeef7;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;