.editArea {
  position: relative;
  height: 200px;

  :global {
    .ql-container {
      height: 104px;
      border: none;
    }

    .ql-mention-list-container {
      max-height: 300px;
      overflow-y: auto;
    }
    .ql-mention-list-item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ql-editor {
      p {
        color: #242e41;
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 26px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 56px;
    padding: 0 8px;

    .tip {
      margin-right: 20px;
      color: #bfc6d2;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;