.pi-gantt-scroll-vertical,
.pi-gantt-scroll-horizontal {
  position: absolute;
  right: 0;
  z-index: 12;
  opacity: 0.9;
}

.pi-gantt-scroll-vertical {
  top: 80px;
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: scroll;
  .pi-gantt-scroll-vertical-inner {
    width: 15px;
  }
}
.pi-gantt-scroll-horizontal {
  bottom: 0;
  width: calc(100% - 270px);
  overflow-x: scroll;
  overflow-y: hidden;

  .pi-gantt-scroll-horizontal-inner {
    height: 15px;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;