.meridiem-picker {
  .ant-picker-panel {
    display: flex;
    flex-direction: row;
    .ant-picker-footer {
      width: 112px;
      min-width: 112px;
      &-extra {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 52px);
        margin-top: 40px;
        border-top: 1px solid #ebedf0;
      }
    }
  }
  &-extra {
    &-item {
      width: 96px;
      height: 40px;
      padding: 0 12px;
      line-height: 40px;
      border-radius: 8px;
      cursor: pointer;
      &.active,
      &:hover {
        background: #f0f6ff;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;