@import '~@/mixin.less';

.layout-modal {
  .ant-modal {
    &-footer {
      border: 0;
    }

    &-body {
      padding-bottom: 42px;
    }
  }
}

.config {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &-item {
    margin-bottom: 16px;

    &.no-wrap {
      display: flex;
      align-items: center;
    }

    &-label {
      width: 64px;

      .font-normal(14px, #767c88);
    }

    .layout {
      &-container {
        display: flex;
        gap: 18px;
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 96px;
        border: 1px solid #ebedf0;
        border-radius: 12px;
        cursor: pointer;
        transition: 0.2s all ease;

        .font-normal(13px, #242e41);

        &-icon {
          color: #6b7a96;
          font-size: 22px;
        }

        &.active {
          color: @lp-theme-color;
          border-color: @lp-theme-color;
        }
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;