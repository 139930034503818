.addTabpane {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  margin-left: 28px;
  border: 1px dashed #ebedf0;
  border-radius: 8px;
  cursor: pointer;
}

.tabpane {
  display: flex;
  align-items: center;
  padding: 6px 0;

  .tabpaneName {
    width: 240px;
    height: 40px;
    padding-left: 12px;
    color: #242d3f;
    line-height: 40px;
    border: 1px solid #ebedf0;
    border-radius: 8px;
    cursor: pointer;
  }
}

.uploader {
  :global {
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
      font-size: 11px;
    }

    .ant-upload-list-picture-card-container,
    .ant-upload.ant-upload-drag {
      width: 40px;
      height: 40px;
    }

    .ant-upload-list-picture-card-container,
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
      margin: 0;
    }

    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;