.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  :global {
    svg {
      filter: drop-shadow(2px 2px 2px var(--shadow-color));
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;