.formulaSettingModal {
  &.fullscreen {
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    margin: 0;
    padding: 0;
    font-size: 14px;
    .modalContent {
      height: 100%;
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px;
}
.editorContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 0;
  &.full {
    margin-top: calc(392px + 32px);
    margin-left: calc(34% + 24px);
  }
}
.switchBar {
  margin: 16px 0 14px 0;
  color: #242e41;
  font-size: 14px;
}
.error {
  height: 20px;
  margin: 5px 0;
  color: #f0665e;
  font-size: 14px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;