.footer {
    height: 72px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #F6F6F6;
}

.nowStatusBtn {
    width: 96px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.refuseBtn, .passBtn {
    width: 100px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;
    cursor: pointer;
}

.refuseBtn {
    background-color: #F0665E;
}

.passBtn {
    background-color: #5ABCB0;
    margin-left: 16px;
}

.main {
    display: flex;

}

.statusLogs {
    width: 240px;
    border-left: 1px solid #F6F6F6;
    padding: 16px;
    :global {
        .ant-steps-item-tail::after {
            background-color: #f0f0f0 !important;
        }
        .ant-steps-icon-dot {
            background-color: #316EF5 !important;
            height: 8px !important;
            width: 8px !important;
        }
    }
}

.header {
    height: 60px;
    border-bottom: 1px solid #F6F6F6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 40px;
}

.title {
    font-size: 20px;
    font-weight: 600;
    color: #242E41;
}

.showStatusLogsBtn {
    width: 88px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F6FF;
    border-radius: 8px;
    color: #316EF5;
    font-size: 14px;
    margin-right: 32px;
    cursor: pointer;
}

.detail {
    padding: 23px 40px 25px 40px;
}

.item {
    margin-top: 32px;
}
.label {
    font-size: 16px;
    font-weight: 600;
    color: #242E41;
    margin-right: 8px;
}

.value {
    margin-top: 8px;
    background-color: #F8F9FB;
    padding: 16px;
    border-radius: 8px;

    :global {
        .ql-container {
            border: none !important;
        }

        .ql-editor {
            padding: 0;
            font-size: 14px;
        }
    }
}

.refuseCommit {
    background: #F8F9FB;
    border-radius: 8px;
    padding: 8px;
    margin-top: 8px;
    margin-left: 20px;
}

.commitModal {
    :global {
        .ant-modal-footer {
            border: none !important;
        }
    }
}

.toppingDetail {
    border-bottom: 1px solid #E5E7EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;

    .box {
        display: flex;
        align-items: center;
    }

    .iconDiv {
        border-radius: 8px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(115,190,209, .1);
        margin-right: 12px;

        :global {
            .iconfont {
                background-clip: text !important;
                color: transparent;
                font-size: 20px;
            }
        }
    }
}

.tag {
    background: #D7E3FF;
    margin-right: 8px;
    height: 32px;
    width: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #316EF5;
    border-radius: 16px;
}
@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;