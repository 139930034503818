.status-modal {
  .ant-form-item-label > label {
    display: block;
    height: 32px;
    overflow: hidden;
    line-height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-picker,
  .ant-input-number-group-wrapper {
    width: 100%;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload-select-picture-card {
    width: 50px;
    height: 50px;
  }
  .anticon-eye {
    display: none;
  }
  .ant-upload-list-item-name {
    font-size: 0;
  }
  .ant-upload-list-item-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadItemContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    &:hover {
      .mask {
        display: flex;
      }
    }

    .uploadItem {
      width: 32px;
      height: 20px;
      background-position: center;
      background-size: cover;
    }

    .mask {
      position: absolute;
      top: 50%;
      left: 50%;
      display: none;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 0 1px;
      background-color: rgba(0, 0, 0, 0.45);
      transform: translate(-50%, -50%);

      .iconfont {
        color: white;
        font-size: 12px;
        cursor: pointer;
      }

      .icondelete {
        margin-right: 3px;
      }
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;