.link-pi-pop {
  &.no-padding {
    padding: 0;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: 6px;
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.1);
  }
  .ant-popover-inner-content {
    padding: 6px 0;
    .link-menus {
      padding: 0 6px;
      //overflow-y: auto;
      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 0 12px;
        color: #374562;
        line-height: 40px;
        border-radius: 8px;
        cursor: pointer;
        span {
          flex-shrink: 0;
        }

        .link-menus-main {
          display: flex;
          flex: 1;
          align-items: center;
          width: 0;
        }

        i {
          margin-right: 8px;
          color: #abb5c3;
          font-size: 16px;
        }
        &.subtitle {
          color: #b7bac0;
          &:hover {
            background-color: #fff;
          }
        }
        &.hasSub {
          flex-direction: column;
          justify-content: center;
          height: 68px;
          line-height: 20px;
          .link-menus-sub {
            color: #b7bac0;
          }
          .link-menus-main {
            flex: unset;
            width: 100%;
          }
        }
        .label-addon {
          display: none;
          align-items: center;
          color: #316ef5;
          font-size: 12px;
          i {
            margin-right: 2px;
            color: #316ef5;
          }
        }
        &.active {
          color: #316ef5;
          background-color: #f4f7fd;
          .label-addon {
            display: flex;
          }
        }
        &:hover {
          background-color: #f8f9fb;
          .label-addon {
            display: flex;
          }
        }
      }
    }
    .service-img {
      padding: 10px;
      border: 2px solid #365ceb;
      border-radius: 27px;
    }
  }

  .template-search-input {
    margin-bottom: 8px;
    background-color: #f8f9fb;
    border: none;
    border-radius: 8px;
    outline: none;
    box-shadow: none;
    .ant-input {
      background-color: #f8f9fb;
    }

    .anticon-search {
      color: #bfc6d2;
    }
  }
}

.extra-component {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 6px 6px 6px;
  background-color: white;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;