.card {
  position: relative;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #ebedf0;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    box-shadow: 0 0 0 2px @primary-color;
    .use {
      display: block;
    }
    .disabled {
      display: block;
    }
  }
  :global(.ant-radio-wrapper) {
    margin-right: 0;
  }
  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: #f5f7fd;
    border-radius: 50%;
  }

  &.checked {
    box-shadow: 0 0 0 2px @primary-color;
  }
  .use {
    position: absolute;
    top: 6px;
    right: 6px;
    display: none;
    color: #316ef5;
    cursor: pointer;
  }
  .disabled {
    position: absolute;
    top: 6px;
    right: 6px;
    display: none;
    color: #bfc6d2;
    // cursor: pointer;
  }
  .using {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px 4px;
    color: white;
    background-color: #316ef5;
    border-radius: 6px 0;
  }
  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .cardDesc {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    color: #767c88;
    font-size: 12px;
    li {
      list-style: disc;
      transform: translateX(8px);
    }
  }
}

.flexContent {
  display: flex;
  justify-content: space-between;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;