.list {
  position: relative;
  width: 100%;
  max-height: 60vh;
  color: #767c88;
  font-size: 12px;

  .listInner {
    position: relative;
    height: 100%;
    &::after {
      position: absolute;
      top: 0;
      left: 109px;
      width: 1px;
      height: 100%;
      background-color: #e5e7eb;
      content: '';
    }
  }
}
.listItem {
  position: relative;
  display: flex;
  width: 100%;
  height: 72px;
  & + .listItem {
    margin-top: 8px;
  }
  &::before {
    position: absolute;
    top: 50%;
    left: 104px;
    z-index: 1;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    transform: translateY(-50%);
    content: '';
  }
  .listItemDate {
    width: 125px;
    height: 100%;
    line-height: 72px;
  }
  .listItemContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 0;
    height: 100%;
    padding: 12px 16px;
    background-color: #f8f9fb;
    border-radius: 8px;
    .contentRow {
      display: flex;
    }
    .value {
      min-width: 20px;
      color: #242e41;
      font-size: 15px;
    }
    .attachmentList {
      .attachment {
        width: 24px;
        height: 24px;
        & + .attachment {
          margin-left: 4px;
        }
      }
    }
    .arrow {
      width: 16px;
      margin: 0 8px;
      transform: rotate(180deg);

      :global {
        i {
          color: #316ef5;
          font-size: 16px;
        }
      }
    }
    .editor {
      display: flex;
      max-width: 50%;
      :global {
        img {
          flex-shrink: 0;
          width: 16px;
          height: 16px;
          margin-right: 4px;
          overflow: hidden;
          border-radius: 8px;
        }
      }
    }
    .commit {
      flex: 1;
      margin-left: 10px;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;