.tabs {
  display: flex;
  justify-content: space-between;
  .tab {
    height: 32px;
    padding: 6px 20px;
    border-radius: 16px;
    color: #316ef5;
    cursor: pointer;
    margin-right: 8px;
    background-color: #eaf0fe;
  }

  .active {
    color: white;
    font-weight: 600;
    background-color: #316ef5;
  }
}

.table {
  padding-top: 20px;
}

.header {
  margin-bottom: 4px;
  display: flex;
  border-radius: 8px;
  background-color: #f9fafc;
  padding: 10px 0;

  .column {
    display: flex;
    align-items: center;
    color: #767c88;
    padding-left: 28px;
  }
}

.item {
  display: flex;
  align-items: center;
  background-color: #f9fafc;
  padding: 16px 0;
  border-radius: 8px;
  margin-bottom: 4px;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;