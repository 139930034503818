.secondary:global(.ant-btn) {
  color: #98a2b6;
  background-color: #f7f9fb;
  border: none;
  border-radius: 4px;

  &:focus,
  &:hover {
    color: #316ef5;
    // background-color: #f0f6ff;
    background-color: #f7f9fb;
  }
  &:active {
    // background-color: #f0f6ff;
    background-color: #f7f9fb;
  }
}

.tiny:global(.ant-btn) {
  height: 24px;
  padding: 0 7px;
  color: #6b7a96;
  font-size: 12px;
  border-radius: 4px;
}

.tiny {
  :global {
    .anticon + span {
      margin-left: 0 !important;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;