.tag {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  color: #242e41;
  font-size: 14px;
  line-height: 1;
  background-color: #f8f9fb;
  border-radius: 8px;
  &.small {
    padding: 0 8px;
    font-size: 12px;
    line-height: 1;
    .delete {
      width: 12px;
      margin-left: 2px;
      background: none;
    }
    :global(.ant-avatar) {
      display: none;
    }
  }
  &.row {
    display: flex;
    padding: 8px 0;
    background-color: white;
    .delete {
      margin-left: auto;
      background-color: #f8f9fb;
    }
  }
}
.moreText {
  display: inline-flex;
  align-items: center;
  height: 24px;
}
.delete {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 4px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.8;
  }
}
.wrapper {
  height: 100%;
  overflow-y: auto;
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;