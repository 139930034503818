.formulaEditor {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 0;
}
.code {
  flex: 1;
  padding: 10px 12px;
  overflow-y: auto;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  transition: all 0.3s;
  &.active,
  &:hover {
    border-color: #316ef5;
  }
  :global {
    .CodeMirror {
      height: 100%;
    }

    .CodeMirror-line span {
      font-family:
        monospace,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI';
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;