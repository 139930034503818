.fs {
  &-modal {
    :global {
      .ant-modal-header {
        border-bottom: 0;
      }

      .ant-btn:not(.ant-btn-group) {
        border-radius: 8px;
      }
    }

    .filter-title {
      box-shadow: 0px 1px 0px 0px #ebeef7;
    }
  }

  &-right {
    box-shadow:
      -4px 0px 8px 0px #ebeef7,
      -1px 0px 0px 0px #ebedf0;
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;